import axios, { AxiosRequestConfig } from 'axios';
import serviceEndpoints from '@Service-endpoints';
import get from 'lodash/get';
import { setCulture } from '@Helpers/content/setCulture';
import getAppEnvironment from '@Helpers/common/getAppEnvironment';
import { trackTrace } from '@Helpers/logging';
import { DEFAULT_MARKET, DEFAULT_LANGUAGE, CMS_API_KEY, PAGE_REQUEST_TIMEOUT } from '@Constants/umbraco';
import { LogReason, SeverityLevel } from '@Constants/logging';

const appConfig = {
	CMS_ENV: getAppEnvironment(),
	APP_ENV: getAppEnvironment(),
};
const appEnv = appConfig.APP_ENV;
const baseURL = `${get(serviceEndpoints.config[appEnv], 'cmsUrl')}/api/v2`;

const request = requestConfig => {
	return new Promise((resolve, reject) => {
		axios(requestConfig)
			.then(response => {
				return resolve(response.data);
			})
			.catch(error => {
				return reject(error);
			});
	});
};

export const client = (
	url,
	market = DEFAULT_MARKET,
	language = DEFAULT_LANGUAGE,
	params = null,
	isCultureOnlyLang = true
): Promise<any> => {
	const culture = isCultureOnlyLang ? setCulture(market, language) : language;

	const requestConfig: AxiosRequestConfig = {
		url: `${url}?culture=${culture}`,
		baseURL,
		headers: {
			'x-api-key': CMS_API_KEY,
			'Accept-Language': culture,
			Accept: 'application/json; charset=utf-8',
		},
		method: 'GET',
		timeout: PAGE_REQUEST_TIMEOUT,
		params,
	};

	return request(requestConfig);
};

const getKeyOfFirstItem = response => get(response, '[0].Key', null);

export const getAllNodeIdsByDocumentType = (documentType: string): Promise<any> => {
	const requestConfig: AxiosRequestConfig = {
		url: `/contentlocator/${documentType}`,
		baseURL,
		method: 'GET',
		headers: {
			'x-api-key': CMS_API_KEY,
		},
	};

	return request(requestConfig).catch(err =>
		trackTrace(err.toString(), SeverityLevel.Error, {
			reason: LogReason.FAILED_TO_FETCH_CONTENT,
			description: `Failed to fetch node id of given document type: ${documentType}`,
		})
	);
};

export const getNodeIdByDocumentType = (documentType: string): Promise<any> => {
	return getAllNodeIdsByDocumentType(documentType).then(getKeyOfFirstItem);
};

export default client;
