import { ReactElement } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import { renderToStaticMarkup } from 'react-dom/server';
import parse from 'html-react-parser';
import { MostFrequentlyAskedQuestion } from '@Partials/FAQ/QuestionItem';
import { transform } from '@Umbraco/helpers/transform';
import getHierarchicalDescendants from '@Umbraco/helpers';
import { client } from '@Umbraco/client';
import CONFIG from '@Umbraco/config.json';
import { toImage } from '@Umbraco/models';
import { trackTrace } from '@Helpers/logging';
import { chain } from '@Helpers/common/array';
import { MostAskedQuestionsData } from '@IspTypes/customer/responseTypes';
import { LogReason, LogRelation, SeverityLevel } from '@Constants/logging';

const FAQ_CATEGORY_LEVEL = 2;

const toRequestType = item => ({
	id: item._id,
	requestTypeName: item.title,
	requestTypeEmail: item.email,
});

const toCategoryItem = item => ({
	identifier: item.identifier,
	title: item.title,
	categoryId: item._id,
});

const toFAQQuestion = item => ({
	id: item._id,
	title: item.title,
	answer: renderToStaticMarkup(parse(item.answer, { replace: transform }) as ReactElement),
	answerImage: get(item, 'image') ? toImage(item.image) : null,
	actionText: get(item, 'actionLink[0].Name', ''),
	actionLink: get(item, 'actionLink[0].Url', ''),
});

const toFAQCategory = (item, pageUrl) => {
	return {
		id: item._id,
		slug: item._url?.replace(pageUrl, '').replace(/\/$/, ''),
		title: item.title,
		description: item.description,
		icon: item.icon,
		childCategories: chain(item.children || [])
			.filter(item => item.contentTypeAlias === 'faqCategory')
			.map(c => toFAQCategory(c, pageUrl))
			.value(),
		questions: chain(item.children || [])
			.filter(item => item.contentTypeAlias === 'faqQuestion')
			.map(toFAQQuestion)
			.value(),
		flowType: get(item, 'flow.contentTypeAlias'),
		flowHelpfulMessage: renderToStaticMarkup(
			parse(get(item, 'flow.answeredMessage', ''), {
				replace: transform,
			}) as ReactElement
		),
		emailFlowNotHelpfulMessage: renderToStaticMarkup(
			parse(get(item, 'flow.unansweredMessage', ''), {
				replace: transform,
			}) as ReactElement
		),
		contactFormSentSuccessful: get(item, 'contactFormSentSuccessful'),
		requestTypeCategories: (get(item, 'flow.requestTypes', []) || []).map(toRequestType),
		identifier: get(item, 'identifier'),
		subcategoryIdentifier: get(item, 'subcategoryIdentifier', ''),
	};
};

const toFAQPage = (faqPage, categories) => {
	return {
		id: faqPage._id,
		slug: faqPage._url,
		title: faqPage.title,
		description: faqPage.description,
		emailTo: faqPage.emailTo,
		emailCc: faqPage.emailCc,
		emailSubject: faqPage.subject,
		emailBody: faqPage.body,
		categories: filter(get(categories, '_embedded.content', []), { contentTypeAlias: 'faqCategory' }).map(c =>
			toFAQCategory(c, faqPage._url)
		),
	};
};

const toQuestionItem = item => ({
	id: item._id,
	question: item.question,
	answer: item.answer,
	category: item.category,
});

const toSearchQuestionItem = item => ({
	id: item._id,
	question: item.title,
	answer: item.answer,
	category: item.category,
});

export const toFAQQuestionItem = (item: MostAskedQuestionsData): MostFrequentlyAskedQuestion => ({
	id: item.Id.toString(),
	topic: item.topic,
	question: item.title,
	answer: item.answer,
	category: item.category,
});

export async function getFAQPage(market: string, lang: string) {
	const id = CONFIG.FAQ_PAGE;

	try {
		const faqPageReq = client(`/content/${id}`, market, lang);
		const categoriesReq = faqPageReq && client(`/content/${id}/children/1/1000`, market, lang);
		const [faqPageRes, categoriesRes] = await Promise.all([faqPageReq, categoriesReq]);

		return toFAQPage(faqPageRes, categoriesRes);
	} catch (err) {
		trackTrace(err.toString(), SeverityLevel.Error, {
			reason: LogReason.FAILED_TO_FETCH_CONTENT,
			relation: LogRelation.FAQ_PAGE,
			market,
			language: lang,
		});
		throw err;
	}
}

export async function getFAQCategory(id, market: string, lang: string, faqPageSlug: string) {
	const categoryReq = client(`/content/${id}`, market, lang);
	const subCategoriesReq = client(`/content/${id}/descendants/1/1000`, market, lang);

	const [categoryRes, subCategoriesRes] = await Promise.all([categoryReq, subCategoriesReq]);
	const hierarchy = getHierarchicalDescendants(id, get(subCategoriesRes, '_embedded.content'));

	return toFAQCategory({ ...categoryRes, children: hierarchy.children }, faqPageSlug);
}

export const getQuestionsWithTopic = (questions, categoryMap) => {
	return questions
		.map(question => ({
			...question,
			topic: get(
				categoryMap.find(category => category.identifier === question.category),
				'title',
				''
			),
		}))
		.filter(question => !isEmpty(question.topic));
};

export const getFAQSearchQuestions = async (market: string, lang: string) => {
	const id = CONFIG.FAQ_PAGE;

	const faqPageReq = client(`content/${id}/descendants/1/1000`, market, lang);
	const [faqPageRes] = await Promise.all([faqPageReq]);
	const descendants = get(faqPageRes, '_embedded.content', []);
	const categoryMap = filter(descendants, { _level: FAQ_CATEGORY_LEVEL, contentTypeAlias: 'faqCategory' }).map(
		toCategoryItem
	);

	const mostFrequentlyAskedQuestions = filter(descendants, { contentTypeAlias: 'mostFrequentlyAskedQuestionItem' }).map(
		toQuestionItem
	);
	const mostAskedQuestionsWithTopic = getQuestionsWithTopic(mostFrequentlyAskedQuestions, categoryMap);

	const faqQuestions = filter(descendants, { contentTypeAlias: 'faqQuestion' }).map(toSearchQuestionItem);
	const faqQuestionsWithTopic = getQuestionsWithTopic(faqQuestions, categoryMap);

	const questionsWithoutDuplicate = faqQuestionsWithTopic.filter(faqItem => {
		let isExist = false;
		mostAskedQuestionsWithTopic.forEach(mostAskedItem => {
			if (
				(faqItem.question === mostAskedItem.question && faqItem.topic === mostAskedItem.topic) ||
				isEmpty(faqItem.topic)
			) {
				isExist = true;
			}
		});
		return !isExist;
	});

	return mostAskedQuestionsWithTopic.concat(questionsWithoutDuplicate);
};
