import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { RootState } from '@Redux';
import ISPLink, { LinkVariant } from '@isp/link';
import ISPButton, { ButtonVariant } from '@isp/button';
import { Newsletter } from '@Umbraco/footer';
import {
	PushDataToTagManagerForButtons,
	PushDataToTagManagerForExternalUrls,
} from '@Helpers/google-analytics/pushDataToTagManager';
import DATA_QA from '@Constants/style/data-qa-selector.json';
import { Buttons, Components, Pages, TagManagerEventKeys, getComponentId } from '@Constants/google-analytics';
import { WindowTargetAttributes } from '@Constants/common';
import { HIDE_NEWSLETTER } from '@Constants/footer';
import './index.scss';

const FooterNewsLetter = () => {
	const newsletter = useSelector<RootState, Newsletter>(state => state.sections.footerContent.newsletter);
	const router = useRouter();

	const showNewsletter =
		!HIDE_NEWSLETTER.includes(router.pathname) &&
		newsletter &&
		!isEmpty(newsletter?.title) &&
		!isEmpty(newsletter?.description) &&
		!isEmpty(newsletter?.id) &&
		!isEmpty(newsletter?.buttonUrl) &&
		!isEmpty(newsletter?.buttonLabel);

	const onSubscribeClick = e => {
		PushDataToTagManagerForExternalUrls(
			TagManagerEventKeys.EXTERNAL_URL_BUTTON_CLICK,
			newsletter.id,
			window.location.href,
			newsletter.buttonUrl
		);
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		window.open(newsletter.buttonUrl, WindowTargetAttributes.BLANK);
	};

	return (
		showNewsletter && (
			<div className="ncj-footer__newsletter grid--item-gap-7">
				<div className="ncj-footer__newsletter__body color-white">
					<div className="DaimlerCS-Bold fs-22">{newsletter.title}</div>
					<div className="fs-16">{newsletter.description}</div>
				</div>
				<ISPLink
					variant={LinkVariant.SECONDARY}
					style={{ color: 'inherit' }}
					destination={newsletter.buttonUrl}
					disableHref
				>
					<ISPButton
						onClick={e => onSubscribeClick(e)}
						outline
						className="p-h-10 fs-16 ncj-footer__newsletter__button"
						variant={ButtonVariant.TERTIARY}
						whiteSpace="normal"
						dataQA={DATA_QA.NEWSLETTER_SUBSCRIBE_BUTTON}
						id={getComponentId([
							Pages.HOME,
							Components.FOOTER,
							Components.NEWSLETTER,
							Components.SUBSCRIBE,
							Buttons.GO_TO_URL,
						])}
					>
						{newsletter.buttonLabel}
					</ISPButton>
				</ISPLink>
			</div>
		)
	);
};

export default FooterNewsLetter;
