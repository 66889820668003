import { IconType, IconVariant } from '@isp/icon';
import { IncidentManagamentNotificationItem, IncidentType } from '@Umbraco/notifications';
import { DeliveryTypes } from '@Reducers/shop/models';
import { OrderHistoryStatus } from '@Constants/webparts/orders';
import { ShopProductKeys } from '@Constants/shop';

export const IFRAME_CONTENT_TIMEOUT = 3000;
export const NOTIFICATION_LOADING_ANIMATION_COUNT = 3;

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const NOTIFICATION_REFRESH_DURATIONS = [10, 15, 30, 60]; // Minutes

export const ORDER_TITLE_TRANSLATION = {
	[OrderHistoryStatus.CONFIRMED]: 'notifications-orders-title-confirm',
	[OrderHistoryStatus.CANCELED]: 'notifications-orders-title-cancel',
};

export const ORDER_DESCRIPTION_TRANSLATION = {
	[OrderHistoryStatus.CONFIRMED]: 'notifications-orders-description-confirm',
	[OrderHistoryStatus.CANCELED]: 'notifications-orders-description-cancel',
};

export enum NotificationsDateLanguage {
	cs = 'cs',
	da = 'da',
	de = 'de',
	el = 'el',
	en = 'en-gb',
	es = 'es',
	fi = 'fi',
	fr = 'fr',
	hu = 'hu',
	it = 'it',
	ko = 'ko',
	no = 'nb',
	nl = 'nl',
	pl = 'pl',
	pt = 'pt',
	ro = 'ro',
	sk = 'sk',
	sv = 'sv',
	tr = 'tr',
	ja = 'ja',
	zh_hk = 'zh-hk',
	zh_cn = 'zh-cn',
	zh_tw = 'zh-tw',
}

export enum NotificationsTypes {
	LICENSE = 'license',
	ORDER = 'order',
	CUSTOM = 'custom',
	LEITSTAND = 'leitstand',
	INCIDENT = 'incident',
}

export type NotificationItemType =
	| IncidentManagamentNotificationItem
	| License
	| OrderNotification
	| CustomCMSNotification
	| LeitstandNotification;

export interface NotificationItems {
	notifications: NotificationItemType[];
	availabilities?: Availability[];
}

export interface Availability {
	id: number;
	eventText: object;
	fullTextMail: object;
	lastModified: number;
	outageType: number;
	outageTypeName: string;
	partialTextMail: object;
	solvedTextMail: object;
	usecaseFullText: object;
	usecasePartialText: object;
	usecaseSolvedText: object;
	textDeu: string;
	textEng: string;
}

export interface License {
	productKey: ShopProductKeys;
	description: JSX.Element;
	title: string;
	type: NotificationsTypes;
}

export interface OrderNotification {
	address: OrdersAddress;
	buttonLabel: string;
	cancelDate: number;
	companyName: string;
	currency: string;
	customerNumber: string;
	dateLabel: string;
	deliveryType: DeliveryTypes;
	description: string;
	exportDate: number;
	gssnId: string;
	companyId: string;
	orderDate: number;
	orderNumber: string;
	orderName: string;
	orderStatus: OrderHistoryStatus;
	price: number;
	status: OrderHistoryStatus;
	title: string;
	type: NotificationsTypes;
	isRated: boolean;
}

export interface CustomCMSNotification {
	type: NotificationsTypes;
	date: string;
	dateLabel: string;
	title: string;
	description: string;
	button?: {
		label: string;
		url: string;
		linkUrl?: string;
		linkCaption?: string;
	};
}

export interface LeitstandNotification {
	type: NotificationsTypes;
	leitstandType: LeitstandType;
	notificationMessage: LeitstandMessageBody;
	startTime: string;
	eventMessage: string;
	additionalMessages: LeitstandMessageBody[];
}

export interface LeitstandMessageBody {
	fullMessage: string;
	partialMessage: string;
	maintenanceMessage: string;
	solvedMessage: string;
}

export enum LeitstandType {
	INCIDENT = 1,
	MAINTENANCE,
	INFORMATION,
}

export const LeitstandIcon = {
	[LeitstandType.INCIDENT]: {
		iconType: IconType.ERROR2,
		iconVariant: IconVariant.BLOODRED,
	},
	[LeitstandType.MAINTENANCE]: { iconType: IconType.CLOCK, iconVariant: IconVariant.AMBER },
	[LeitstandType.INFORMATION]: { iconType: IconType.INFO, iconVariant: IconVariant.AZURE },
};

export const LeitstandTitle = {
	[LeitstandType.INCIDENT]: 'common:incident',
	[LeitstandType.MAINTENANCE]: 'common:planned-maintenance',
	[LeitstandType.INFORMATION]: 'common:general-information',
};

export const INCIDENT_NOTIFICATION_TYPE_ICON_MAP = {
	[IncidentType.INCIDENT]: {
		iconType: IconType.ERROR2,
		iconVariant: IconVariant.BLOODRED,
	},
	[IncidentType.PLANNED_MAINTENANCE]: { iconType: IconType.CLOCK, iconVariant: IconVariant.AMBER },
	[IncidentType.GENERAL_INFORMATION]: { iconType: IconType.INFO, iconVariant: IconVariant.AZURE },
};

export const INCIDENT_NOTIFICATION_ICON_TYPE_TITLE_MAP = {
	[IncidentType.INCIDENT]: 'common:incident',
	[IncidentType.PLANNED_MAINTENANCE]: 'common:planned-maintenance',
	[IncidentType.GENERAL_INFORMATION]: 'common:general-information',
};

export interface OrdersAddress {
	description: string;
	zipCode: string;
	city: string;
	country: string;
	region: OrdersAddressesRegion;
}

export interface OrdersAddressesRegion {
	region: string;
	subRegion: string;
}

export const CMS_EMPTY_DATE_VALUE = '0001-01-01T00:00:00';
export const INCIDENT_NOTIFICATION_DESCRIPTION_MAX_CHAR = 120;

export const NOTIFICATION_CLASSNAMES = {
	itemAnimation: 'notifications-menu__content__item-animation p-h-10',
	itemLoading: 'notifications-menu__content__item--loading',
	groupHeader: 'notifications-menu__group-header lh-13 p-l-3 p-b-1 fs-16 color-brownish-grey',
};

export const NOTIFICATION_STYLES = {
	emptyContentContainer: {
		justifyContent: 'center',
		alignItems: 'center',
		height: '84vh',
	},
	activeNotificationButtonWidth: {
		minWidth: '150px',
		width: 'auto',
	},
};

export enum NotificationsDirection {
	LEFT,
	RIGHT,
}

export enum NotificationTheme {
	LIGHT,
	DARK,
}
