import isEmpty from 'lodash/isEmpty';
import { toFormattedPrice } from '@isp/price';
import requestData from '@Network/index';
import { BaseOrder, Order } from '@Reducers/orders/models';
import { isCacheDataUsed } from '@Helpers/common/checkCacheDataUsage';
import { ArvatoOrderDetailsEUResponse, RMIOrdersRoWResponse } from '@IspTypes/onlineShopping/responseTypes';
import { PartOrderDetailResponse, PartOrderResponse, RawPartsOrderItem } from '@IspTypes/customer/responseTypes';
import { OrderStockType } from '@Constants/account';
import { ResponseCode } from '@Constants/common';
import { OrderType } from '@Constants/orders';

export interface Currency {
	unit: string;
	symbolBeforeAmount: boolean;
}

export interface PriceFraction {
	commaSeparator: boolean;
	digitCount: number;
}

export interface IPrice {
	amount: number;
	currency: Currency;
	fraction: PriceFraction;
}

export interface VAT {
	rate: number;
	value: string;
}

export interface SpecialOffer {
	specialOfferDiscountAbsolute: string;
	specialOfferDiscountPercent: number;
	specialOfferFrom: string;
	specialOfferTo: string;
}

export interface CompanyAddress {
	description: string;
	zipCode: string;
	city: string;
	country: string;
}

export interface PartsOrderPrice {
	totalSum: string;
	listPriceSum: string;
	netPriceSum: string;
	discountSum?: string;
	specialOfferDiscountSum?: string;
	deliveryCost?: string;
	vat: VAT;
	currency: string;
}

export interface PartsOrderItemPrice {
	itemListPrice: string;
	itemNetPrice: string;
	itemDiscount: string;
	itemDiscountPercent: number;
	specialOffer: SpecialOffer;
	netPriceSum: string;
	netPriceSumIncludingVat: string;
}

export interface PartsOrderItem {
	name: string;
	partNumber: string;
	isStarPart: boolean;
	isRemanPart: boolean;
	discountGroup: string;
	quantity: number;
	price: PartsOrderItemPrice;
}

export interface OrderRetailer {
	companyName: string;
	address: CompanyAddress;
	numberOfDecimals: number;
}

export interface OrderCustomer {
	customerId: string;
	companyName: string;
	address: CompanyAddress;
}

export interface PartsOrderDetails {
	orderNumber: string;
	orderComment?: string;
	orderDate: Date;
	orderedBy: string;
	jobNumber?: string;
	orderType?: OrderStockType;
	vin?: string;
	price: PartsOrderPrice;
	orderItems: PartsOrderItem[];
	retailerInfo: OrderRetailer;
	customerInfo: OrderCustomer;
}

const toFormattedOrderItem = (item): PartsOrderItem => {
	const { price } = item;
	const { specialOffer, itemListPrice, itemNetPrice, itemDiscount, netPriceSum, netPriceSumIncludingVat } = price;
	const { specialOfferDiscountAbsolute } = specialOffer || {};

	return {
		...item,
		price: {
			...price,
			specialOffer: !isEmpty(specialOffer) && {
				...specialOffer,
				specialOfferDiscountAbsolute: `- ${toFormattedPrice(specialOfferDiscountAbsolute)}`,
			},
			itemListPrice: toFormattedPrice(itemListPrice, true),
			itemNetPrice: toFormattedPrice(itemNetPrice, true),
			itemDiscount: itemDiscount.amount !== 0 ? `- ${toFormattedPrice(itemDiscount)}` : '-',
			netPriceSum: toFormattedPrice(netPriceSum, true),
			netPriceSumIncludingVat: toFormattedPrice(netPriceSumIncludingVat, true),
		},
	};
};

const toFormattedArvatoOrderItem = item => {
	const { price } = item;
	return {
		...item,
		rawPrice: price,
		price: toFormattedPrice(price),
	};
};

const toFormattedArvatoOrderDetailItem = order => {
	const { price, totalPrice, vat, items, deliveryCosts } = order;
	const { value } = vat || {};
	return {
		...order,
		rawPrice: price,
		price: toFormattedPrice(price),
		totalPrice: toFormattedPrice(totalPrice),
		deliveryCosts: toFormattedPrice(deliveryCosts),
		items: items.map(item => toFormattedArvatoOrderItem(item)),
		vat: {
			...vat,
			value: toFormattedPrice(value, true),
		},
	};
};

export function getArvatoOrderDetails(orderIdList) {
	return requestData('GET', 'ARVATO_ORDER_DETAILS', { params: { name: 'orderIdList', values: orderIdList } })
		.then((res: ArvatoOrderDetailsEUResponse) => {
			const { data } = res;
			const arvatoOrders = data?.map(order => toFormattedArvatoOrderDetailItem(order));
			return { ...res, data: arvatoOrders };
		})
		.catch(err => {
			throw err;
		});
}

export const toOrdersData = ({
	totalPrice = null,
	price = null,
	deliveryCosts = null,
	orderType = OrderType.RMI,
	orderDate,
	...rest
}) => {
	return {
		totalPrice: toFormattedPrice(totalPrice),
		rawPrice: price,
		price: toFormattedPrice(price),
		deliveryCosts: toFormattedPrice(deliveryCosts),
		orderType,
		orderDate,
		...rest,
	};
};

const toOrdersResponse = ({
	data,
	...restOfResponse
}: {
	count: number;
	data: BaseOrder[] | Order[];
	responseCode: ResponseCode;
	succeed: boolean;
}) => {
	const orderList = data?.map(toOrdersData);
	return { data: orderList, count: orderList.length, ...restOfResponse };
};

export function getWebpartsOrders() {
	return requestData('GET', 'PART_ORDERS', null, null, true)
		.then(res => {
			return { cacheDataUsed: isCacheDataUsed(res), ...toOrdersResponse(res.data as PartOrderResponse) };
		})
		.catch(err => {
			throw err;
		});
}

export function getRMIOrders() {
	return requestData('GET', 'RMI_ORDERS', null, null, true)
		.then(res => {
			return { cacheDataUsed: isCacheDataUsed(res), ...toOrdersResponse(res.data as RMIOrdersRoWResponse) };
		})
		.catch(err => {
			throw err;
		});
}

export function getOrderRatings(orderNumbers: string[]) {
	return requestData('GET', 'GET_ORDER_RATINGS', { params: { name: 'orderNumbers', values: orderNumbers } })
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function saveOrderRating(data) {
	return requestData('POST', 'SAVE_ORDER_RATING', { bodyData: data })
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getPartsOrderDetails(orderId) {
	return requestData('GET', 'GET_PARTS_ORDER_DETAILS', {
		interpolate: { orderId },
	})
		.then((res: PartOrderDetailResponse) => {
			const { price, orderItems } = res.data;
			const { totalSum, listPriceSum, netPriceSum, discountSum, specialOfferDiscountSum, deliveryCost, vat } = price;
			const { value } = vat || {};
			const orderDetails: PartsOrderDetails = {
				...res.data,
				price: {
					...price,
					totalSum: toFormattedPrice(totalSum, true),
					listPriceSum: toFormattedPrice(listPriceSum, true),
					netPriceSum: toFormattedPrice(netPriceSum, true),
					discountSum: toFormattedPrice(discountSum),
					specialOfferDiscountSum: toFormattedPrice(specialOfferDiscountSum),
					deliveryCost: toFormattedPrice(deliveryCost),
					vat: {
						...vat,
						value: toFormattedPrice(value, true),
					},
					currency: netPriceSum?.currency?.unit,
				},
				orderItems: orderItems.map((item: RawPartsOrderItem) => toFormattedOrderItem(item)),
			};

			return orderDetails;
		})
		.catch(err => {
			throw err;
		});
}

export const getPartsOrderXLSXFile = (id: number) => {
	return requestData('GET', 'PARTS_ORDER_XLSX_EXPORT', {
		interpolate: { id },
		isFile: true,
	}).then(res => res);
};

export const getPartsOrderXFRFile = (id: number) => {
	return requestData('GET', 'PARTS_ORDER_XFR_EXPORT', {
		interpolate: { id },
		isFile: true,
	}).then(res => res);
};
