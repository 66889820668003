import { IconType, IconVariant } from '@isp/icon';
import routes from '@Routes';
import {
	GET_DIAGNOSIS_BASKET_PRODUCTS,
	GET_DIGITAL_BASKET_PRODUCTS,
	GET_EQUIPMENT_BASKET_PRODUCTS,
	GET_WEBPARTS_BASKET_PRODUCTS,
} from '@Reducers/shop/actions';
import { Markets } from '@Constants/market';
import { OrderType } from '@Constants/orders';
import { ProductKeys, SubscriptionTypes } from '@Constants/product';
import { VehicleBrands } from '@Constants/vehicle';

export const SHOP_BASKET_NOTIFICATION_TIMEOUT = 4000;
export const WEBPART_PRICE_DIVIDER = 10;
export const KOREAN_PRICE_MULTIPLIER = 100;
export const KOREAN_DECIMAL_MIN = 5;
export const KOREAN_DECIMAL_MAX = 50;
export const WEBPART_DISCOUNT_DIVIDER = 100;
export const SHOP_BASKET_NOTIFICATION_DISPLAY_TIMEOUT = 500;
export const ADDITIONAL_INFO_NOTIFICATION_DISPLAY_TIMEOUT = 300;
export const ADDITIONAL_INFO_NOTIFICATION_TIMEOUT = 4000;
export const REFERENCE_TEXT_MAX_CHAR = 35;
export const HARDWARE_ID_MAX_CHAR = 12;
export const TWO_FACTOR_MAX_CHAR = 7;
export const JOB_NUMBER_MAX_CHAR = 20;
export const SHOPPING_BASKET_ERROR_ID = -1;
export const ALL_SQUARE_BRACKETS = /\[|\]/g;
export const MARKETS_WITHOUT_SUBSCRIPTION_PLAN = [Markets.CANADA, Markets.TURKEY];
export const MARKETS_XENTRY_DIAGNOSIS_CONTACT_CARD_SHOWN = [Markets.DENMARK, Markets.SWEDEN];
export const MARKETS_SUPPORT_PROCESS_NOT_ALLOWED_PUBLIC = [Markets.TAIWAN];
export const MARKETS_ORDERS_SENT_VIA_MAIL = [Markets.CHINA, Markets.TAIWAN];
export const MARKETS_VAT_INCLUDED = [Markets.TAIWAN];
export const MARKETS_VAT_INCLUDED_PRODUCT_PAGES = [Markets.AUSTRALIA, Markets.TAIWAN];
export const MARKETS_NOT_HAVING_LIST_PRICES = [Markets.JAPAN];
export const MARKETS_NOT_ALLOW_DIGITAL_PRODUCT = [Markets.JAPAN, Markets.DUBAI];

export enum ShopProductKeys {
	WIS = 'WIS',
	ASRA = 'ASRA',
	XENTRY_TIPS = 'XENTRY_TIPS',
	XENTRY_TIPS_MOBILE = 'XENTRY_TIPS_MOBILE',
	PARTS_INFORMATION = 'PARTS_INFORMATION',
	XENTRY_SCOPE = 'XENTRY_SCOPE',
	XENTRY_REMOTE_DIAGNOSIS_APP = 'XRD',
	RDS = 'RDS',
	RMS = 'RMS',
	PASS_THRU = 'PASS_THRU',
	XENTRY_Pass_Thru_EU = 'XENTRY_Pass_Thru_EU', // it is only used on Application tab
	XDLITE = 'XENTRY DIAGNOSIS LITE', // it is only used on Application tab TODO
	TIME_CREDITS = 'TIME_CREDITS',
	PASS_THRU_EXTENSION = 'PASS_THRU_EXTENSION',
	XD4IW = 'XD4IW',
	XD4IW_TIME_CREDITS = 'XD4IW_TIME_CREDITS',
	XD4IW_EXTENSION = 'XD4IW_EXTENSION',
	B2B_CONNECT_APP = 'B2B_CONNECT_APP',
	PAY_PER_VIN = 'PPV',
}

export enum TimePeriodTypes {
	HOUR = 'HOUR',
	DAY = 'DAY',
	WEEK = 'WEEK',
	MONTH = 'MONTH',
	YEAR = 'YEAR',
}

export enum PartAvailability {
	RED_LED_NOT_ALLOWED = -1,
	GRAY_UNKNOWN,
	GREEN_WITH_CHECKMARK,
	GREEN,
	YELLOW,
	ORANGE,
	RED,
	BLACK,
	BLUE,
	WHITE,
}

export const SHORT_LABEL_TO_VEHICLE_TYPE = {
	CV: VehicleBrands.COMMERCIAL_VEHICLE,
	PC: VehicleBrands.PASSENGER_CAR,
	SMART: VehicleBrands.SMART,
};

export const ORDER_TYPE_BASKET_NAME = {
	[OrderType.DIGITAL]: 'shop:basket-for-digital-products',
	[OrderType.EQUIPMENT]: 'shop:basket-for-equipment-products',
	[OrderType.DIAGNOSIS]: 'shop:basket-for-diagnosis-products',
	[OrderType.WEBPARTS]: 'shop:basket-for-webpart-products',
};

export const BASKET_TYPE_GET_BASKET_FUNCTION_MAPPING = {
	[OrderType.DIAGNOSIS]: GET_DIAGNOSIS_BASKET_PRODUCTS(),
	[OrderType.DIGITAL]: GET_DIGITAL_BASKET_PRODUCTS(),
	[OrderType.EQUIPMENT]: GET_EQUIPMENT_BASKET_PRODUCTS(),
	[OrderType.WEBPARTS]: GET_WEBPARTS_BASKET_PRODUCTS(),
};

export const BASKET_PRODUCT_COUNT_SELECTOR_MAPPING = {
	[OrderType.DIGITAL]: 'addedTotalProductCount',
	[OrderType.EQUIPMENT]: 'addedTotalProductCount',
	[OrderType.DIAGNOSIS]: 'addedTotalProductCount',
	[OrderType.WEBPARTS]: 'totalProductCount',
};

export enum BasketStatus {
	VALID,
	ARVATO_API_FAILED,
	UMAS_API_FAILED,
	WEB_PARTS_API_FAILED,
}

export enum BasketValidationStatus {
	VALID = 0,
	ALREADY_IN_BASKET = 101,
	USER_HAS_ALREADY_LICENSE,
	PRODUCT_HAS_PREREQUISITE,
	PRODUCT_NOT_PURCHASABLE,
	INSUFFICIENT_STOCK,
	SYSTEM_EXPIRED,
	USER_NO_ACCESS,
	UNATHORIZED = 110,
	PPV_MAXIMUM_ORDER_QUANTITY = 116,
	PPV_RELATED_INVOICE_LICENSES_ACTIVE,
	UNKNOWN_ERROR = -1,
	UNKNOWN_GEMS_ID = 1,
	MULTIPLE_USERS_FOUND,
	UNKNOWN_USER,
	NOT_ISP_USER,
	EXISTING_HARDWARE_ID,
	MAXIMUM_ORDER_QUANTITY,
	CONTRACT_DOES_NOT_EXIST,
	CONTRACT_NOT_ACTIVE,
	CONTRACT_NOT_EXTENSIBLE,
	DUPLICATED_HARDWARE_ID,
	INVALID_CUSTOM_PARAMETER,
	MISSING_REQUIRED_CUSTOM_PARAMETER,
	UMAS_PRODUCT_DUPLICATED,
	TIME_CREDIT_NOT_COMPATIBLE,
	CONTRACT_EXTENSION_NOT_COMPATIBLE,
}

export const BASKET_VALIDATION_TRANSLATION = {
	[BasketValidationStatus.ALREADY_IN_BASKET]: 'shop:already-in-your-basket',
	[BasketValidationStatus.USER_HAS_ALREADY_LICENSE]: 'shop:user-has-active-license',
	[BasketValidationStatus.PRODUCT_NOT_PURCHASABLE]: 'shop:item-not-available',
	[BasketValidationStatus.INSUFFICIENT_STOCK]: 'shop:quantity-exceeds-stock',
	[BasketValidationStatus.SYSTEM_EXPIRED]: 'shop:system-fee-expired',
	[BasketValidationStatus.USER_NO_ACCESS]: 'shop:user-not-authorized',
	[BasketValidationStatus.UNATHORIZED]: 'shop:user-not-authorized',
	[BasketValidationStatus.PPV_MAXIMUM_ORDER_QUANTITY]: 'shop:ppv-max-order-quantity',
	[BasketValidationStatus.PPV_RELATED_INVOICE_LICENSES_ACTIVE]: 'shop:ppv-invoice-license-info',
	[BasketValidationStatus.UNKNOWN_GEMS_ID]: 'shop:no-user-with-gems-id',
	[BasketValidationStatus.MULTIPLE_USERS_FOUND]: 'shop:multiple-user-company-membership',
	[BasketValidationStatus.UNKNOWN_USER]: 'shop:unknown-user-no-company-relation',
	[BasketValidationStatus.NOT_ISP_USER]: 'shop:user-is-not-isp',
	[BasketValidationStatus.EXISTING_HARDWARE_ID]: 'shop:product-cant-be-added-active-license-exist',
	[BasketValidationStatus.MAXIMUM_ORDER_QUANTITY]: 'shop:reached-the-max-order-quantity',
	[BasketValidationStatus.CONTRACT_DOES_NOT_EXIST]: 'shop:contract-with-system-number-not-exist',
	[BasketValidationStatus.CONTRACT_NOT_ACTIVE]: 'shop:contract-not-active',
	[BasketValidationStatus.CONTRACT_NOT_EXTENSIBLE]: 'shop:contract-not-extensible',
	[BasketValidationStatus.DUPLICATED_HARDWARE_ID]: 'shop:duplicated-hardware-id',
	[BasketValidationStatus.INVALID_CUSTOM_PARAMETER]: 'shop:invalid-customer-parameter',
	[BasketValidationStatus.MISSING_REQUIRED_CUSTOM_PARAMETER]: 'shop:missing-required-custom-parameter',
	[BasketValidationStatus.UMAS_PRODUCT_DUPLICATED]: 'shop:duplicated-product',
	[BasketValidationStatus.TIME_CREDIT_NOT_COMPATIBLE]: 'shop:time-credit-not-compatible',
	[BasketValidationStatus.CONTRACT_EXTENSION_NOT_COMPATIBLE]: 'shop:contract-extension-not-compatible',
};

export const DURATIONS_TO_LABELS_MAPPING = {
	[SubscriptionTypes.HOUR]: 'common:hours-label',
	[SubscriptionTypes.DAY]: 'common:days-label',
	[SubscriptionTypes.WEEK]: 'common:weeks-label',
	[SubscriptionTypes.MONTH]: 'common:months-label',
	[SubscriptionTypes.YEAR]: 'common:years-label',
	[SubscriptionTypes.CREDIT]: 'common:credit-label',
};

export enum ShoppingBasketAccessStatus {
	REQUEST_NEEDED = 'REQUEST_NEEDED',
	PENDING = 'PENDING',
	AUTHORIZED = 'AUTHORIZED',
	PARTIALLY_AUTHORIZED = 'PARTIALLY_AUTHORIZED',
	ERROR = 'ERROR',
}

export enum ShoppingBoxStatus {
	AUTHORIZED = 'AUTHORIZED',
	PENDING = 'PENDING',
	REQUEST_ACCESS_ROW = 'REQUEST_ACCESS_ROW',
	REQUEST_ACCESS_XENTRY = 'REQUEST_ACCESS_XENTRY',
	STATE_INFO_NEEDED = 'STATE_INFO_NEEDED',
	DISABLED = 'DISABLED',
}

export const ACCESS_REQUIRED_NOTE_STATUSES = [
	ShoppingBoxStatus.REQUEST_ACCESS_ROW,
	ShoppingBoxStatus.REQUEST_ACCESS_XENTRY,
];

export const ConvertedBoxStatusROW = {
	[ShoppingBasketAccessStatus.AUTHORIZED]: ShoppingBoxStatus.AUTHORIZED,
	[ShoppingBasketAccessStatus.PENDING]: ShoppingBoxStatus.PENDING,
	[ShoppingBasketAccessStatus.REQUEST_NEEDED]: ShoppingBoxStatus.REQUEST_ACCESS_ROW,
	[ShoppingBasketAccessStatus.PARTIALLY_AUTHORIZED]: ShoppingBoxStatus.REQUEST_ACCESS_XENTRY,
	[ShoppingBasketAccessStatus.ERROR]: ShoppingBoxStatus.AUTHORIZED,
};

export const FeaturedPriceConfig = {
	WIS: {
		subscriptionType: SubscriptionTypes.HOUR,
		vehicleType: VehicleBrands.SMART,
		upperlineKey: 'from',
		sublineKey: 'for-hour',
	},
	ASRA: {
		subscriptionType: SubscriptionTypes.HOUR,
		vehicleType: VehicleBrands.SMART,
		upperlineKey: 'from',
		sublineKey: 'for-hour',
	},
	PARTS_INFORMATION: {
		subscriptionType: SubscriptionTypes.YEAR,
		sublineKey: 'for-year',
	},
	XENTRY_TIPS: {
		subscriptionType: SubscriptionTypes.HOUR,
		vehicleType: VehicleBrands.SMART,
		upperlineKey: 'from',
		sublineKey: 'for-hour',
	},
	XENTRY_PASS_THRU_EU: {
		subscriptionType: SubscriptionTypes.HOUR,
		vehicleType: VehicleBrands.SMART,
		upperlineKey: 'from',
		sublineKey: 'for-hour',
	},
	XENTRY_DIAGNOSIS_SYSTEM: {
		subscriptionType: SubscriptionTypes.FIXED,
	},
	XRD: {
		subscriptionType: SubscriptionTypes.HOUR,
		vehicleType: VehicleBrands.PASSENGER_CAR,
		upperlineKey: 'from',
	},
	PTI: {
		subscriptionType: SubscriptionTypes.HOUR,
		vehicleType: VehicleBrands.SMART,
		upperlineKey: 'from',
		sublineKey: 'for-hour',
	},
	XENTRY_TIPS_APP: {
		subscriptionType: SubscriptionTypes.YEAR,
		vehicleType: VehicleBrands.PASSENGER_CAR,
		upperlineKey: 'from',
		sublineKey: 'for-year',
	},
};

export enum BasketContent {
	BASKET_SUMMARY,
	CHECKOUT_SUMMARY,
	WEBPART_CHECKOUT,
}
export const BASKET_CONTENT_TYPE_MAP = {
	[OrderType.WEBPARTS]: BasketContent.WEBPART_CHECKOUT,
	[OrderType.DIAGNOSIS]: BasketContent.CHECKOUT_SUMMARY,
	[OrderType.DIGITAL]: BasketContent.CHECKOUT_SUMMARY,
	[OrderType.EQUIPMENT]: BasketContent.CHECKOUT_SUMMARY,
	[OrderType.RMI]: BasketContent.CHECKOUT_SUMMARY,
};
export enum PaymentStatus {
	SUCCESS = 'SUCCESS',
	FAILED = 'FAILED',
}

export enum BasketQueryBoolean {
	TRUE = 'True',
	FALSE = 'False',
}

export enum PlacedOrderTypes {
	UNKNOWN = 'UNKNOWN',
	DIAGNOSIS = 'DIAGNOSIS',
	DIGITAL = 'DIGITAL',
	EQUIPMENT = 'EQUIPMENT',
	RMI = 'RMI',
	PPV = 'PPV',
}

export interface PageConfig {
	orderId: string;
	basketContent: BasketContent;
	reason: string;
	isSuccessModalOpened: boolean;
	isFailedModalOpened: boolean;
	hasRemainingBasket: BasketQueryBoolean;
	orderProcessed: BasketQueryBoolean;
	orderType: PlacedOrderTypes;
}

export const REQUEST_ACCESS_STATES = [ShoppingBoxStatus.REQUEST_ACCESS_ROW, ShoppingBoxStatus.REQUEST_ACCESS_XENTRY];

export const DIAGNOSIS_LICENSES_ROUTE_MAPPING = {
	[ShopProductKeys.XENTRY_Pass_Thru_EU]: routes.XENTRY_PASS_THRU_EU,
	[ShopProductKeys.XDLITE]: routes.XD_LITE,
};

export const LICENCE_KEY_PRODUCT_TYPE_MAPPING = {
	[ProductKeys.PARTS_INFORMATION]: ShopProductKeys.PARTS_INFORMATION,
	[ProductKeys.XRD]: ShopProductKeys.XENTRY_REMOTE_DIAGNOSIS_APP,
	[ProductKeys.XENTRY_TIPS]: ShopProductKeys.XENTRY_TIPS,
	[ProductKeys.XENTRY_TIPS_APP]: ShopProductKeys.XENTRY_TIPS_MOBILE,
	[ProductKeys.WIS]: ShopProductKeys.WIS,
	[ProductKeys.ASRA]: ShopProductKeys.ASRA,
	[ProductKeys.DIGITAL_SERVICE_BOOKLET]: ProductKeys.DIGITAL_SERVICE_BOOKLET,
};

export const SYSTEM_SELECTION_CONTENT = {
	[ShopProductKeys.PASS_THRU]: {
		title: 'shop:xentry-pass-thru-eu-systems-title',
		preparePCTitle: 'shop:xentry-pass-thru-eu-prepare-pc-box-title',
		preparePCDescription: 'shop:xentry-pass-thru-eu-prepare-pc-box-description',
		preparePCDownloadButton: 'shop:xentry-pass-thru-eu-prepare-pc-box-download-button-title',
		purchaseBoxTitle: 'shop:xentry-pass-thru-eu-purchase-box-title',
		purchaseBoxDescription: 'shop:xentry-pass-thru-eu-purchase-box-description',
	},
	[ShopProductKeys.XD4IW]: {
		title: 'shop:xd4iw-initial-setup',
		preparePCTitle: 'shop:xd4iw-prepare-pc-box-title',
		preparePCDescription: 'shop:xd4iw-prepare-pc-box-description',
		preparePCDownloadButton: 'shop:xd4iw-prepare-pc-box-download-button-title',
		purchaseBoxTitle: 'shop:xd4iw-purchase-box-title',
		purchaseBoxDescription: 'shop:xd4iw-purchase-box-description',
	},
};

export const EXTENSION_SELECTION_CONTENT = {
	[ShopProductKeys.PASS_THRU_EXTENSION]: {
		title: 'shop:xentry-pass-thru-eu-extensions-title',
		boxTitle: 'shop:xentry-pass-thru-eu-extensions-box-title',
		systemTitle: 'shop:xentry-pass-thru-eu-extensions-box-system-title',
		description: 'shop:xentry-pass-thru-eu-extensions-box-description',
	},
	[ShopProductKeys.XD4IW_EXTENSION]: {
		title: 'shop:xd4iw-extensions-title',
		boxTitle: 'shop:xd4iw-extensions-box-title',
		systemTitle: 'shop:xd4iw-extensions-box-system-title',
		description: 'shop:xd4iw-extensions-box-description',
	},
};

export enum DiagnosisAvailabilityStatus {
	NOT_APPLICABLE,
	NOT_AVAILABLE,
	LOW_AVAILABILITY,
	AVAILABLE,
}

export const STOCK_AVAILABILITY_ICON_MAPPING = {
	[DiagnosisAvailabilityStatus.NOT_AVAILABLE]: {
		type: IconType.CIRCLE,
		variant: IconVariant.BLOODRED,
	},
	[DiagnosisAvailabilityStatus.LOW_AVAILABILITY]: {
		type: IconType.CIRCLE,
		variant: IconVariant.AMBER,
	},
	[DiagnosisAvailabilityStatus.AVAILABLE]: {
		type: IconType.CIRCLE,
		variant: IconVariant.LIME_GREEN,
	},
};

export enum PARTS_BASKET_CATEGORIES {
	WEBPARTS,
	FIN_BASED = 'FIN_BASED',
	RETAILER = 'RETAILER',
}
