import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import some from 'lodash/some';
import get from 'lodash/get';
import routes from '@Routes';
import NextI18Next from '@Lib/i18n';
import { Retailer } from '@Reducers/organization/models';
import { PageVisitHistory } from '@Reducers/pageVisitHistory/models';
import { ProductRoute } from '@Reducers/faq/models';
import { AppDispatch, RootState } from '@Redux';
import ModalWrapper from '@Shared/modals/ModalWrapper';
import Header from '@Shared/header';
import StickyToolbar from '@Shared/header/StickyToolbar';
import IncidentManagementBanner from '@Shared/header/IncidentManagementBanner';
import Sidebar from '@Shared/sidebar';
import Feedback from '@Shared/Feedback';
import { getTermsAndDataProtectionData } from '@Umbraco/terms-and-data-protection';
import { SeoMeta } from '@Umbraco/metaData';
import CONFIG from '@Umbraco/config.json';
import { GET_ORGANIZATION_DEALERS } from '@Reducers/organization/actions';
import { GET_PRODUCT_ROUTES } from '@Reducers/faq/actions';
import { GET_FILLING_TAB_DATA } from '@Reducers/vehicle/actions';
import {
	GET_FOOTER_GROUPS,
	GET_LATEST_RELEASE,
	GET_LUCKY_DRAW_CONTENT,
	GET_MENU_ITEMS,
	GET_NEWS,
	GET_NEWS_LETTER,
	GET_READ_NEWS,
	GET_SIDEBAR_CONTENT,
} from '@Reducers/sections/actions';
import { GET_SATISFACTION_FEEDBACK_SCORE_USER_ENABLE } from '@Reducers/user/actions';
import { TOGGLE_LUCKY_DRAW_MODAL } from '@Reducers/modals/actions';
import { GET_NOTIFICATIONS } from '@Reducers/notification/actions';
import { GET_BASKET_TOTAL_COUNT } from '@Reducers/shop/actions';
import { DeliveryTypes } from '@Reducers/shop/models';
import { NewsItem } from '@Umbraco/news';
import { UPDATE_PAGE_VISIT_HISTORY } from '@Reducers/pageVisitHistory/actions';
import { PushDataToTagManagerForScrollDepth } from '@Helpers/google-analytics/pushDataToTagManager';
import { StorageKeys, isLuckyDrawDisplayed } from '@Helpers/storage';
import { TriggerLoginEvent, UrlWithoutHash } from '@Helpers/TriggerLoginEvent';
import { geti18nLanguage } from '@Helpers/language/geti18nLanguage';
import { clearCacheRemanRequestModal } from '@Helpers/products/reman';
import useMozillaScroll from '@Helpers/hooks/useMozillaScroll';
import { useAppHeight } from '@Helpers/hooks/useAppHeight';
import { useScroll } from '@Helpers/hooks/useEvent';
import useLanguageChange from '@Helpers/hooks/useLanguageChange';
import useHeaderHeight from '@Helpers/hooks/useHeaderHeight';
import { useDSB } from '@Helpers/dsb/useDSB';
import { useLiveQuery } from 'dexie-react-hooks';
import { createUserForPersonalSettings, getPersonalSettings } from '@User-operations';
import { useDidMountEffect } from '@Helpers/hooks/useDidMountEffect';
import { userSettingsDb } from '@Dexie/userSettingsDB';
import { isLuckyDrawContestToBeReminded } from '@Helpers/storage/localStorage';
import { getMarketConfigurations } from '@Configurations/index';
import { setCookie } from '@Helpers/cookies/manageCookies';
import Loading, { LoadingSizes } from '@isp/loading';
import { LayoutBackgrounds, UNAVAILABLE_COUNTRY } from '@Constants/common';
import { SCROLL_TIMEOUT, TagManagerEventKeys } from '@Constants/google-analytics';
import { SidebarGroups, SidebarTabs } from '@Constants/menu';
import { FeedbackBackground } from '@Constants/home';
import { VehicleDetails } from '@Constants/vehicle';
import './index.scss';
import { OrderStockType } from '@Constants/account';
import { EssentialCookies } from '@Constants/cookies';

const Footer = dynamic(() => import('@Shared/footer'), { ssr: true });
const PostLoginModals = dynamic(() => import('@Shared/modals/PostLoginModals'), { ssr: false });
const LoginError = dynamic(() => import('@Shared/modals/LoginError'), { ssr: false });
const BasketNotifications = dynamic(() => import('@Partials/Basket/Notifications'), { ssr: false });
const Campaigns = dynamic(() => import('@Partials/NewHome/Campaigns'), { ssr: false });
const VehicleCard = dynamic(() => import('@Partials/Vehicle/VehicleCard'), { ssr: false });

export interface LayoutProps {
	background?: LayoutBackgrounds;
	dealers?: Retailer;
	productRoutes: ProductRoute[];
	isUserLoggedIn: boolean;
	loginError: string;
	children?: ReactNode;
	metaData?: SeoMeta;
	pageVisitHistory: PageVisitHistory[];
	canonicalURL?: string;
	activeTab?: SidebarTabs;
	completeOverlay?: boolean;
	campaigns?: boolean;
	news?: boolean;
	showVinDropdown?: boolean;
	showBackToVehicles?: boolean;
	showBackToCatalogueEntry?: boolean;
	showSidebar?: boolean;
	showRetailerDropdown?: boolean;
	showNetPriceToggle?: boolean;
	showFeedback?: boolean | FeedbackBackground;
	hideNotifications?: boolean;
	hideToolbar?: boolean;
	vehicleDetailsLoading?: boolean;
	newsItems: NewsItem[];
}

export const LAYOUT_NAMESPACES = [
	'common',
	'users',
	'support',
	'login',
	'footer',
	'home',
	'account',
	'retailers',
	'vehicles',
];

const Layout: FC<LayoutProps> = ({
	dealers,
	productRoutes,
	isUserLoggedIn,
	loginError,
	children,
	metaData,
	pageVisitHistory,
	canonicalURL,
	activeTab,
	completeOverlay = false,
	background = LayoutBackgrounds.WHITE,
	campaigns = false,
	showVinDropdown = false,
	showBackToVehicles = true,
	showBackToCatalogueEntry = false,
	showSidebar = false,
	showRetailerDropdown = true,
	showFeedback = false,
	showNetPriceToggle = true,
	hideNotifications = false,
	hideToolbar = false,
	vehicleDetailsLoading,
	newsItems,
}) => {
	const router = useRouter();
	const { market, vin } = router.query;
	const [termsOfUseData, setTermsOfUseData] = useState(null);
	const [feedbackInit, setFeedbackInit] = useState(false);
	const [layoutWidthShift, setLayoutWidthShift] = useState(0);
	const sidebarWrapperRef = useRef(null);
	const dispatch: AppDispatch = useDispatch();
	const sidebar = useSelector<RootState, any[]>(state => state.sections.sidebar);
	const vehicleDetails = useSelector<RootState, VehicleDetails>(state => state.vehicle.vehicleDetails);
	const country = useSelector<RootState>(state => state.user.country);
	const vehicleDataCardAvailable = useSelector<RootState, boolean>(state => state.vehicle.vehicleDataCardAvailable);
	const vehicleDataCardAvailableForNonSavedVIN = useSelector<RootState, boolean>(
		state => state.catalogue.vehicleDataCardAvailable
	);
	const [userSettingsData, userSettingsDataLoaded] = useLiveQuery(
		() => userSettingsDb.userSettingsData.toArray().then(data => [data[data?.length - 1], true]),
		[],
		[]
	);
	const language = geti18nLanguage(NextI18Next.i18n.language);

	const hasLoginError = loginError !== null;
	const sidebarActiveGroup = sidebar?.find(item => item.identifier === activeTab)?.group;
	const isVehiclePage = sidebarActiveGroup === SidebarGroups.CATALOGUE && !isEmpty(vin);
	const showVehicleCard =
		!vehicleDetailsLoading && isVehiclePage && (vehicleDataCardAvailable || vehicleDataCardAvailableForNonSavedVIN);
	const isMarketAllowed =
		country === null || (market as string).toUpperCase().indexOf(country as string) > UNAVAILABLE_COUNTRY;
	useMozillaScroll();
	useHeaderHeight();

	const handleLanguageChangeEvents = () => {
		if (isUserLoggedIn && isMarketAllowed) {
			getTermsOfUseData();
			getNotifications();
			getReadNews();
			getNews();
			getLuckyDraw();
		}
		getReleasesContent();
		setHTMLLangAttribute();
		getSidebarContent();
		dispatch(GET_NEWS_LETTER(market, language));
		dispatch(GET_FOOTER_GROUPS(market, language));
		dispatch(GET_MENU_ITEMS(market, language));
		dispatch(GET_PRODUCT_ROUTES(CONFIG.FAQ_SOFTWARE, market, language, null));
	};

	const getNews = () => dispatch(GET_NEWS(market, language, isUserLoggedIn));
	const getReadNews = () => dispatch(GET_READ_NEWS());
	const getNotifications = () => dispatch(GET_NOTIFICATIONS(market, language));
	const getReleasesContent = () => dispatch(GET_LATEST_RELEASE(market, language));
	const getSidebarContent = () => dispatch(GET_SIDEBAR_CONTENT(market, language));
	const getLuckyDraw = () => {
		dispatch(GET_LUCKY_DRAW_CONTENT(market, language)).then(activeContest => {
			if (activeContest && !isLuckyDrawDisplayed() && isLuckyDrawContestToBeReminded(activeContest.id))
				dispatch(TOGGLE_LUCKY_DRAW_MODAL());
		});
	};

	const handleScroll = () => {
		const scrollPercentage = 100;
		const currentPath = `${window.location.hostname + window.location.pathname.toLowerCase()}`;
		const elm = document.body;
		const p = elm.parentNode as HTMLDivElement;
		const pos = ((elm.scrollTop || p.scrollTop) / (p.scrollHeight - p.clientHeight)) * scrollPercentage;
		const roundedPosition = Math.round(pos);
		const isPageVisited = some(pageVisitHistory, page => page.pathName === currentPath);

		if (roundedPosition === scrollPercentage && !isPageVisited) {
			dispatch(UPDATE_PAGE_VISIT_HISTORY({ pathName: currentPath, isVisited: true }));
			PushDataToTagManagerForScrollDepth(TagManagerEventKeys.SCROLL_DEPTH, currentPath);
		}
	};

	const debouncedHandleScroll = debounce(handleScroll, SCROLL_TIMEOUT);

	const getTermsOfUseData = () => {
		getTermsAndDataProtectionData(market as string, language).then(termsOfUseData => {
			setTermsOfUseData(termsOfUseData);
		});
	};

	const setHTMLLangAttribute = () => {
		document.documentElement.lang = language;
	};

	const createPersonalSettings = async availableCatalogLanguages => {
		const { market } = router.query;

		let allowedMarketLang = '';
		let unavailablePartOrderTypes = [];
		const isMarketAllowed =
			country === null || (market as string).toUpperCase().indexOf(country as string) > UNAVAILABLE_COUNTRY;

		if (!isMarketAllowed) {
			const selectedCountry = typeof country === 'string' ? country : country?.[0];
			const marketConfiguration = await getMarketConfigurations(selectedCountry);
			allowedMarketLang = get(marketConfiguration, 'languageConfiguration.defaultLanguage');
			unavailablePartOrderTypes = get(marketConfiguration, 'unavailablePartOrderTypes');
		}

		const initialPersonalSettings = {
			netPriceShortcut: false,
			partCatalogView: true,
			catalogLanguage: allowedMarketLang || language,
			orderType: unavailablePartOrderTypes.includes(OrderStockType.IMMEDIATE_REQUIREMENT)
				? OrderStockType.STOCK_ORDER
				: OrderStockType.IMMEDIATE_REQUIREMENT,
			deliveryType: DeliveryTypes.COLLECTION,
		};
		createUserForPersonalSettings(initialPersonalSettings).then(async () => {
			try {
				await userSettingsDb.userSettingsData.add({
					useShowNetPriceShortcut: initialPersonalSettings.netPriceShortcut,
					isVehiclePartsListView: initialPersonalSettings.partCatalogView,
					selectedPartLanguage: initialPersonalSettings.catalogLanguage,
					contentLanguages: availableCatalogLanguages,
					selectedWebPartsOrderType: initialPersonalSettings.orderType,
					selectedDeliveryType: initialPersonalSettings.deliveryType,
				});
			} catch (err) {
				userSettingsDb.userSettingsData.add({
					useShowNetPriceShortcut: null,
					isVehiclePartsListView: null,
					selectedPartLanguage: '',
					contentLanguages: [],
					selectedWebPartsOrderType: '',
					selectedDeliveryType: '',
				});
			}
		});
	};

	const getPersonalSets = () => {
		if (userSettingsDataLoaded && (!userSettingsData || isEmpty(userSettingsData?.contentLanguages))) {
			getPersonalSettings()
				.then(async res => {
					if (isEmpty(res?.settings)) {
						createPersonalSettings(res?.availableCatalogLanguages || []);
					}
					try {
						await userSettingsDb.userSettingsData.add({
							useShowNetPriceShortcut: res.settings?.netPriceShortcut,
							isVehiclePartsListView: res.settings?.partCatalogView,
							selectedPartLanguage: res.settings?.catalogLanguage,
							contentLanguages: res.availableCatalogLanguages,
							selectedWebPartsOrderType: res.settings?.selectedWebPartsOrderType,
							selectedDeliveryType: res.settings?.selectedDeliveryType,
						});
					} catch (err) {
						userSettingsDb.userSettingsData.add({
							useShowNetPriceShortcut: false,
							isVehiclePartsListView: true,
							selectedPartLanguage: language,
							contentLanguages: [],
							selectedWebPartsOrderType: OrderStockType.IMMEDIATE_REQUIREMENT,
							selectedDeliveryType: DeliveryTypes.COLLECTION,
						});
					}
				})
				.catch(async () => {
					await userSettingsDb.userSettingsData.add({
						useShowNetPriceShortcut: false,
						isVehiclePartsListView: true,
						selectedPartLanguage: language,
						contentLanguages: [],
						selectedWebPartsOrderType: OrderStockType.IMMEDIATE_REQUIREMENT,
						selectedDeliveryType: DeliveryTypes.COLLECTION,
					});
				});
		}
	};

	const getSatisfactionScoreUserEnable = () => {
		dispatch(GET_SATISFACTION_FEEDBACK_SCORE_USER_ENABLE());
	};

	const getBasketCount = () => {
		dispatch(GET_BASKET_TOTAL_COUNT());
	};

	const getFillingTabData = () => {
		dispatch(GET_FILLING_TAB_DATA(vehicleDetails.vinNumber));
	};

	useScroll(debouncedHandleScroll);
	useMozillaScroll();
	useAppHeight();
	useHeaderHeight();
	useLanguageChange(handleLanguageChangeEvents);
	useDSB();

	useDidMountEffect(() => {
		if (!isEmpty(vehicleDetails.vinNumber)) getFillingTabData();
	}, [vehicleDetails.vinNumber]);

	useEffect(() => {
		setFeedbackInit(true);
		getReleasesContent();
		setHTMLLangAttribute();
		TriggerLoginEvent(UrlWithoutHash('order-'));

		if ('scrollRestoration' in history) {
			history.scrollRestoration = 'manual';
		}

		if (isUserLoggedIn && isMarketAllowed) {
			setCookie(EssentialCookies.PUBLIC_LANGUAGE_SELECTION, '');
			getBasketCount();
			getTermsOfUseData();
			getSidebarContent();
			getNotifications();
			getSatisfactionScoreUserEnable();
			getReadNews();
			getLuckyDraw();
			if (isEmpty(newsItems)) getNews();
			if (isEmpty(dealers)) dispatch(GET_ORGANIZATION_DEALERS());
			if (isEmpty(productRoutes)) dispatch(GET_PRODUCT_ROUTES(CONFIG.FAQ_SOFTWARE, market, language, null));
		}
		if (
			router.pathname !== routes.REMAN_PARTS_PRODUCTS &&
			sessionStorage.getItem(StorageKeys.STORAGE_KEY_REMAN_REQUEST_MODAL)
		) {
			clearCacheRemanRequestModal();
		}
	}, []);

	useEffect(() => {
		if (isUserLoggedIn && isMarketAllowed) getPersonalSets();
	}, [userSettingsDataLoaded]);

	useEffect(() => {
		if (!sidebarWrapperRef?.current) return noop;
		const resizeObserver = new ResizeObserver(() => {
			setLayoutWidthShift(sidebarWrapperRef.current?.clientWidth ?? 0);
		});
		resizeObserver.observe(sidebarWrapperRef.current);
		return () => {
			resizeObserver.disconnect();
		};
	}, []);

	useEffect(() => {
		window.onbeforeunload = () => {
			if (!isUserLoggedIn) setCookie(EssentialCookies.PUBLIC_LANGUAGE_SELECTION, language);
		};

		return () => {
			window.onbeforeunload = null;
		};
	}, []);

	return (
		<div>
			<div>
				<Head>
					<title>{get(metaData, 'title') || 'Mercedes-Benz B2B Connect'}</title>
					<meta charSet="utf-8" />
					<meta name="viewport" content="initial-scale=1, width=device-width" />
					<meta name="google-site-verification" content="8aPqfccwaXDisfyxCQOuBxqtFRxSKJa_OjGjhhnzRQE" />
					<meta name="description" content={metaData?.description || ''} />
					{canonicalURL && <link rel="canonical" href={canonicalURL} />}
				</Head>
				<Header hidden={completeOverlay} />
				{isUserLoggedIn && !isMarketAllowed && <Loading size={LoadingSizes.MEDIUM} className="text-center p-10" />}

				{isUserLoggedIn && isMarketAllowed && (
					<>
						{!hideNotifications && <IncidentManagementBanner />}
						<div className="pos-rel">
							{!hideToolbar && (
								<StickyToolbar
									showVinDropdown={activeTab === SidebarTabs.CATALOGUE_CAMPAIGNS ? !!vin : showVinDropdown}
									showBackToVehicles={showBackToVehicles}
									showBackToCatalogueEntry={showBackToCatalogueEntry}
									showRetailerDropdown={showRetailerDropdown}
									showNetPriceToggle={showNetPriceToggle}
								/>
							)}
							<div className="disp-grid layout__body" data-sidebar-disabled={!showSidebar}>
								<div className="layout__body__sidebar" ref={sidebarWrapperRef} data-sidebar-disabled={!showSidebar}>
									<Sidebar activeTab={activeTab} showSidebar={showSidebar} />
								</div>
								<div
									className={`layout__body__content h-100-p ${background}`}
									style={{ maxWidth: showSidebar ? `calc(100vw - ${layoutWidthShift}px)` : '100vw' }}
								>
									{children}
									{showFeedback && isUserLoggedIn && feedbackInit && isMarketAllowed && (
										<Feedback
											key={window.location.href}
											className={
												Object.values(FeedbackBackground).includes(showFeedback as FeedbackBackground) && showFeedback
											}
										/>
									)}
								</div>
								{showVehicleCard && <VehicleCard />}
							</div>
						</div>
					</>
				)}
				{!isUserLoggedIn && children}
				{campaigns && <Campaigns />}
				{hasLoginError && <LoginError error={loginError} />}
				{isUserLoggedIn && <PostLoginModals termsOfUseData={termsOfUseData} />}
			</div>
			<ModalWrapper />
			<Footer />
			{isUserLoggedIn && isMarketAllowed && <BasketNotifications />}
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	isUserLoggedIn: state.user.isUserLoggedIn,
	loginError: state.user.loginError,
	dealers: state.organization.dealers,
	pageVisitHistory: state.pageVisitHistory,
	productRoutes: state.faq.productRoutes,
	vehicleDetailsLoading: state.vehicle.vehicleDetailsLoading,
	newsItems: state.sections.news,
});

export default connect(mapStateToProps)(Layout);
