import { IPrice } from '@Apis/orders';
import {
	DiagnosisBasketProduct,
	DigitalBasketProduct,
	EquipmentBasketProduct,
	PartsBasket,
	PartSubBasket,
	PartSubBasketProduct,
} from '@Reducers/shop/models';
import { isEmpty } from 'lodash';
import { AlternativePartTypes, ExplosionDrawingPart, SearchPart } from '@Reducers/vehicle/models';
import { extractNumericValue } from '@Helpers/shop/price';
import { DECIMAL_BASE } from '@Constants/google-analytics';

export const getItemNumericPrice = (price: IPrice) => {
	if (isEmpty(price)) return null;

	const { amount, fraction } = price;
	const { digitCount } = fraction || {};

	return amount / DECIMAL_BASE ** digitCount;
};

export const getItemCategoryRemanPart = (part: SearchPart | PartSubBasketProduct) => {
	return part?.coreValuePerUnit ? AlternativePartTypes[AlternativePartTypes.REMAN_PART] : undefined;
};

export const getItemDataFromPartSubBasketProducts = (partSubBasketProducts: PartSubBasketProduct[]) => {
	return partSubBasketProducts?.map(part => {
		const netPrice = getItemNumericPrice(part.rawNetPricePerUnit);
		const listPrice = getItemNumericPrice(part.rawListPricePerUnit);
		return {
			item_id: part.partNumber?.split(' ').join(''),
			item_name: part.partName,
			quantity: part.quantity,
			price: netPrice,
			discount: listPrice - netPrice,
			item_category: getItemCategoryRemanPart(part),
		};
	});
};

export const getItemDataFromPartSubBasket = (partSubBasketList: PartSubBasket[]) => {
	const subBasketList = partSubBasketList?.flatMap(subBasket => subBasket.partList.filter(part => part.isSelected));
	return getItemDataFromPartSubBasketProducts(subBasketList);
};

export const getItemDataFromPartsBasket = (partsBasket: PartsBasket) => {
	const partBasketList = partsBasket?.partBasketList?.flatMap(partBasket => partBasket.partSubBasketList);
	return getItemDataFromPartSubBasket(partBasketList);
};

export const getItemDataFromBasketProducts = (
	basketProducts: (DiagnosisBasketProduct | DigitalBasketProduct | EquipmentBasketProduct)[],
	basketType?: string
) =>
	basketProducts?.map(product => ({
		price: getItemNumericPrice(product.rawPrice) || Number(extractNumericValue(product.price) || product.price),
		item_id: product.productId?.split(' ').join(''),
		item_name: product.productName,
		item_category: basketType,
	}));

export const getItemDataFromExplosionDrawingParts = (parts: ExplosionDrawingPart[]) =>
	parts?.map(part => ({
		item_id: part.partNo || part.partNumber?.split(' ').join(''),
		item_name: part.displayName || part.title,
		quantity: part.selectedQuantity,
		price: getItemNumericPrice(part?.shoppingDetails?.price?.rawNetPricePerUnit),
		discount:
			getItemNumericPrice(part?.shoppingDetails?.price?.rawListPricePerUnit) -
			getItemNumericPrice(part?.shoppingDetails?.price?.rawNetPricePerUnit),
	}));
