import { useEffect } from 'react';
import { getWebpartsCampaignBanners } from '@Apis/shop';
import { usePreferredAddress } from '@Helpers/webparts/usePreferredAddress';
import { RootState } from '@Redux';
import routes, { getRoutesAs } from '@Routes';
import { CampaignTheme } from '@Umbraco/sales-offers';
import { truncate } from 'lodash';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { SET_CAMPAIGNS } from '@Reducers/catalogue/actions';
import {
	WebpartsCampaign,
	BANNER_TITLE_LENGTH_WITH_IMAGE,
	BANNER_TITLE_LENGTH,
	BANNER_DESCRIPTION_LENGTH,
	BANNER_BUTTON_LENGTH,
} from '@Constants/webparts';
import { WindowTargetAttributes } from '@Constants/common';

const useCampaigns = () => {
	const router = useRouter();
	const { vin, model, modelName, pageSource } = router.query;
	const dispatch = useDispatch();
	const campaigns = useSelector<RootState, WebpartsCampaign[]>(state => state.catalogue.campaigns);
	const userId = useSelector<RootState, string>(state => state.user.userId);
	const { gssnId, customerNumber, companyId } = usePreferredAddress();

	const handleClick = (campaignId: string, partsListAvailable: boolean, fileURL: string) => {
		if (partsListAvailable) {
			const url = routes.CATALOGUE_CAMPAIGNS;
			const queryParams = {
				...(vin && { vin }),
				...(model && { model }),
				...(modelName && { modelName }),
				...(pageSource && { pageSource }),
				...(campaignId && { campaign: campaignId }),
			};
			router.push({
				pathname: getRoutesAs(url, router.query),
				query: queryParams,
			});
		} else {
			window.open(fileURL, WindowTargetAttributes.BLANK);
		}
	};

	const updateCampaigns = () => {
		getWebpartsCampaignBanners(gssnId, userId, companyId, customerNumber).then(res => {
			const partCampaigns = res
				.filter(campaign => campaign.flyerDocumentName || campaign.hasParts)
				.map(campaign => {
					const { teaserImageUrl, name, id, subtitle, text, hasParts, flyerDocumentUrl } = campaign;
					return {
						id: id.toString(),
						title: truncate(name, {
							length: teaserImageUrl ? BANNER_TITLE_LENGTH_WITH_IMAGE : BANNER_TITLE_LENGTH,
						}),
						description: truncate(subtitle, { length: BANNER_DESCRIPTION_LENGTH }),
						media: { url: teaserImageUrl },
						button: { label: truncate(text, { length: BANNER_BUTTON_LENGTH }) },
						campaignTheme: teaserImageUrl ? CampaignTheme.LIGHT : CampaignTheme.ICONS,
						onClick: () => handleClick(id, hasParts, flyerDocumentUrl),
					};
				});
			dispatch(SET_CAMPAIGNS(partCampaigns));
		});
	};

	useEffect(() => {
		// when there is either vin or model, router query is ready
		if (!gssnId || !customerNumber) return;
		updateCampaigns();
	}, [gssnId, customerNumber, companyId]);

	return campaigns;
};

export default useCampaigns;
