/* eslint-disable */
const { initReactI18next } = require('react-i18next');
const NextI18Next = require('next-i18next').default;
const HttpApi = require('i18next-http-backend');
const NAMESPACES = require('./namespaces');
const ENDPOINTS = require('../src/utils/network/internal-url/service-endpoints.json');

let options = {
	otherLanguages: [
		'de',
		'en',
		'en-AU',
		'ro',
		'cs',
		'da',
		'el',
		'es',
		'be',
		'fi',
		'fr',
		'in',
		'it',
		'ko',
		'nl',
		'no',
		'pl',
		'pt',
		'sv',
		'tr',
		'zh',
		'zh-HK',
		'zh-TW',
		'hu',
		'sk',
		'ja',
		'en-JA', //For Japan market, Umbraco's ISO code for English Japan is en-JA therefore we cannot use en-JP in here
		'en-AE',
	],
	defaultNS: ['common'],
	ns: NAMESPACES,
};

if (process.browser) {
	const getConfig = require('next/config').default;
	const { publicRuntimeConfig } = getConfig();
	const config = { APP_ENV: publicRuntimeConfig.APP_ENV || 'dev' };
	const environment = config.APP_ENV.toUpperCase();
	const { cmsBrowserTranslationKeyUrl } = ENDPOINTS.config[environment.toUpperCase()];

	options = {
		...options,
		browserLanguageDetection: false,
		use: [initReactI18next, HttpApi],
		react: {
			useSuspense: true,
		},
		backend: {
			loadPath: cmsBrowserTranslationKeyUrl,
			crossDomain: true,
		},
	};
} else {
	const { setGlobalDispatcher, Agent } = require('undici');
	setGlobalDispatcher(new Agent({ connect: { timeout: 60_000 } }));
	const config = { APP_ENV: process.env.APP_ENV || 'dev' };
	const environment = config.APP_ENV.toUpperCase();
	const { cmsBrowserTranslationKeyUrl } = ENDPOINTS.config[environment];

	options = {
		...options,
		use: process.env.APP_ENV ? [initReactI18next, HttpApi] : [initReactI18next],
		react: {
			useSuspense: true,
		},
		backend: {
			loadPath: cmsBrowserTranslationKeyUrl,
		},
	};
}

module.exports = new NextI18Next(options);
