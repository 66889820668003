import { IPrice, SpecialOffer } from '@Apis/orders';
import { OrderType } from '@Constants/orders';
import { BasketValidationStatus, PartAvailability, PaymentStatus, TimePeriodTypes } from '@Constants/shop';
import { PartsBasketType } from '@Constants/webparts';
import { FinalVehicleType, SubscriptionTypes } from '@Constants/product';
import { EquipmentAvailabilityStatus } from '@Constants/product/equipment';
import { OrderStockType } from '@Constants/account';

export interface ShopActionType {
	DELETE_PRODUCT_FROM_BASKET: Function;
	DELETE_WEBPARTS_FROM_BASKET: Function;
	UPDATE_BASKET: Function;
	TOGGLE_SHOPPING_BASKET_NOTIFICATION: Function;
	GET_ORDER_ADDRESSES: Function;
	UPDATE_HARDWARE_ID: Function;
	GET_ACCESS_STATUS: Function;
	UPDATE_ACCESS_STATUS: Function;
	SET_ROW_ORDER_PRODUCTS: Function;
	PLACE_WEBPARTS_ORDER: Function;
	UPDATE_LAST_DELETED_BASKET: Function;
	UPDATE_BASKET_MULTIPLE_ITEMS: Function;
	SET_SHOW_NET_PRICE: Function;
	SET_PART_SEARCH_INSIDE_BASKET: Function;
}

export interface BasketUpdateItem {
	partNumber: string;
	quantity: number;
}

export interface UpdateBasketMultipleItemsRequestBody {
	parts: BasketUpdateItem[];
	finNumber: string;
	basketDealer: BasketDealerInfoSummary;
}

export interface UpdateWebpartsBasketRequestBody {
	finNumber: string;
	partNumber: string;
	quantity: number;
	basketDealer: BasketDealerInfoSummary;
	isRecommended: boolean;
	catalogRef?: CatalogueReference;
}

export interface UpdateEquipmentBasketRequestBody {
	productId: string;
	quantity: number;
}

export interface UpdateDigitalBasketRequestBody {
	productId: string;
	quantity: number;
	customInputParameters: object;
}

export interface UpdateDiagnosisBasketRequestBody {
	productId: string;
	customInputParameters: object;
}

export interface ConfirmOrderBasketRequestData {
	deliveryAddressId?: string;
	shoppingBasketId: number;
	isCostToBeRounded?: boolean;
	isRow?: boolean;
	provider: PaymentProvider;
	language?: string;
}

export interface UpdateEquipmentBasketOptions {
	data: UpdateEquipmentBasketRequestBody;
	addedProductName: string;
	showNotification?: boolean;
	quantity?: number;
}

export interface WebpartsDeleteItem {
	positionIds: [number];
	basketDealer: BasketDealerInfoSummary;
}

export interface DeleteWebpartsRequestBody {
	deleteBasketPositionRequests: [WebpartsDeleteItem];
}

export interface UpdateBasketOptions {
	data:
		| UpdateEquipmentBasketRequestBody
		| UpdateDigitalBasketRequestBody
		| UpdateDiagnosisBasketRequestBody
		| UpdateWebpartsBasketRequestBody;
	addedProductName: string;
	updateBasketFunction: Function;
	showNotification?: boolean;
	quantity?: number;
	useTimeZone?: boolean;
	cartType?: string;
}

export interface ConfirmBasketOptions {
	basketType: OrderType;
	requestData: ConfirmOrderBasketRequestData;
	confirmBasketFunction: Function;
}

export interface PartCheckoutDealer {
	gssnId: string;
	customerNumber: string;
	selectedDeliveryType: DeliveryTypes;
	selectedOrderType: OrderStockType;
}

export interface PartCheckoutRequestOptions {
	deselectedPartIds: number[];
	finNumber: string;
	partBasketCheckoutSummaryDealerList: PartCheckoutDealer[];
}

export interface AllBasketsContainer {
	digitalBasket: DigitalBasket;
	diagnosisBasket: DiagnosisBasket;
	equipmentBasket: EquipmentBasket;
	webpartsBasket: PartsBasket;
}

export interface TimePeriod {
	count: string;
	type: TimePeriodTypes;
}

export interface BasketDealerInfoSummary {
	companyId: string;
	customerNumber: string;
	gssnId: string;
	userId: string;
	supportedOrderTypes?: OrderStockType[];
}

export interface DealerVisibilities {
	isDiscountGroupVisible: boolean;
	isListPriceSumVisible: boolean;
	isSpecialOfferDiscountVisible: boolean; // check if it can be used
	isVatVisible: boolean;
}

export interface ShopRetailerType {
	id: string;
	gssnId?: string;
	groupId?: string;
	dealerName: string;
	street: string;
	zip: string;
	city: string;
	numberOfDecimals: number;
	currency: string;
	visibilities?: DealerVisibilities;
}

export interface WebpartsPlaceOrderList {
	basketDealer: BasketDealerInfoSummary;
	showFinToDealer: boolean;
	deliveryType: DeliveryTypes;
	orderType: OrderStockType;
	selectedPartIds: number[];
	basketId: number;
}

export enum DeliveryTypes {
	COLLECTION = 'COLLECTION',
	DELIVERY = 'DELIVERY',
	COLLECTION_STATION = 'COLLECTION_STATION',
}

export enum DiagnosisProductStockAvailabilityType {
	NOT_APPLICABLE = 0,
	NOT_AVAILABLE = 1,
	LOW_AVAILABILITY = 2,
	AVAILABLE = 3,
}

export enum PaymentProvider {
	MPAY = 'MPay',
	TELECASH = 'Telecash',
}

export interface CustomInputParameter {
	HARDWARE_ID: string;
	SYSTEM_NUMBER: string;
	user_ID: string;
}

export interface NonPartsBasketProduct {
	productId: string;
	productName: string;
	shoppingBasketProductId: number;
	rawPrice: IPrice;
	price: string;
	validation: ShoppingBasketItemValidation;
	purchaseAllowed: boolean;
}
export interface EquipmentBasketProduct extends NonPartsBasketProduct {
	quantity: number;
	stockAvailabilityType: EquipmentAvailabilityStatus;
}

export interface DiagnosisBasketProduct extends NonPartsBasketProduct {
	stockAvailabilityType: DiagnosisProductStockAvailabilityType;
	customInputParameters: CustomInputParameter;
}

export interface DigitalBasketProduct extends NonPartsBasketProduct {
	userId: string;
	systemNumber: string;
	durationAmount: string;
	durationType: SubscriptionTypes;
	vehicleDivision: FinalVehicleType;
	quantity: number;
	priceIncludingVAT: string;
	productKey: string;
	customInputParameters: CustomInputParameter;
}

export interface BasketResponse {
	basketType: OrderType;
	shoppingBasketId: number;
	lastUpdateDateTime: string;
	isValid: boolean;
	externalApiStatus: number;
	subTotalPrice: IPrice;
	deliveryFee: IPrice;
	totalNetPrice: IPrice;
	addedTotalProductCount: number;
	validationStatus: BasketValidationStatus;
	vat: ValueAddedTax;
}

export interface EquipmentBasket extends BasketResponse {
	equipmentProductList: EquipmentBasketProduct[];
}
export interface DiagnosisBasket extends BasketResponse {
	diagnosisProductList: DiagnosisBasketProduct[];
}
export interface DigitalBasket extends BasketResponse {
	digitalProductList: DigitalBasketProduct[];
}

export type NonPartsBasket = DiagnosisBasket | DigitalBasket | EquipmentBasket;

export interface PartsBasket {
	basketType: OrderType;
	currency: string;
	isValid: boolean;
	lastModificationDate: string;
	partBasketList: PartBasket[];
	totalListPrice: IPrice;
	totalNetPrice: IPrice;
	totalProductCount: number;
}

export interface PartBasket {
	baseDiscounts: IPrice;
	currency: string;
	finNumber: string;
	isValid: boolean;
	lastModificationDate: string;
	partBasketType: PartsBasketType;
	partSubBasketList: PartSubBasket[];
	sumOfListPrices: IPrice;
	sumOfNetPrices: IPrice;
	totalListPrice: IPrice;
	totalNetPrice: IPrice;
	totalProductCount: number;
	totalSpecialOffer: IPrice;
	validationStatus: number;
	vat: ValueAddedTax;
	vatForListPrice: ValueAddedTax;
}

export interface PartCheckoutBasket extends PartBasket {
	totalDeliveryCost: IPrice;
}

export interface PartsDeliveryInformation {
	shippingAddress: OrderAddress;
	selectedDeliveryType: DeliveryTypes;
	selectedOrderType: OrderStockType;
	deliveryCostNet: IPrice;
	deliveryCostGross: IPrice;
	deliveryTypes: DeliveryTypes[];
}

export interface PartDeliveryPrices {
	totalListPricesWithDelivery: IPrice;
	totalNetPricesWithDelivery: IPrice;
	vatWithDelivery: ValueAddedTax;
	vatForListPriceWithDelivery: ValueAddedTax;
}

export interface LogisticInfo {
	tooltip: string;
}

export interface PartSubBasket {
	currency: string;
	customerInfo: BasketDealerInfoSummary;
	dealer: ShopRetailerType;
	id: number;
	partList: PartSubBasketProduct[];
	vat: ValueAddedTax;
	totalListPrice: IPrice;
	totalNetPrice: IPrice;
	deliveryInformation: PartsDeliveryInformation;
	deliveryPrices: PartDeliveryPrices;
	vatForListPrice: ValueAddedTax;
	logisticInfoDelivery: LogisticInfo;
	logisticInfoCollection: LogisticInfo;
}

export type CatalogueReference = {
	mainGroupId: string;
	subGroupId: string;
	moduleId?: string;
	catalogProductId?: string;
	modelId?: string;
	productId?: string;
	aggregateModelId?: string;
	aggregateProductId?: string;
	aggregateTypeId?: string;
	catalogModelId?: string;
	isMaterialPart?: boolean;
};

export interface PartSubBasketProduct {
	availability: PartAvailability;
	availabilityHint: string;
	baseDiscountAbsolute: string;
	baseDiscountPercent: number;
	coreValuePerUnit: string;
	discountGroup: number;
	isFasterAvailable: boolean;
	isSelected: boolean;
	listPricePerUnit: string;
	rawListPricePerUnit: IPrice;
	netPricePerUnit: string;
	rawNetPricePerUnit: IPrice;
	partName: string;
	partNumber: string;
	positionId: number;
	quantity: number;
	specialOffer: SpecialOffer;
	sumListPrice: string;
	sumNetPrice: string;
	tooltip: string;
	catalogRef: CatalogueReference;
}

export interface ShoppingBasketItemValidation {
	status: BasketValidationStatus;
	invalidParameter?: string | null;
}

export interface ValueAddedTax {
	rate: number;
	value: IPrice;
}

export interface VehicleTypeSelectionData {
	disabled: boolean;
	label: string;
	checked: boolean;
}

export interface TimePeriodSelectionData {
	checked: boolean;
	count: string;
	label: string;
	price?: number;
}

export interface ProductsOrderedROW {
	productName: string;
	isPurchaseSuccessful: boolean;
}

export interface OrderedProduct {
	productName: string;
	productId: string;
	purchasedStatus: PaymentStatus;
	transactionId: string;
}
export interface OrderWithoutPayment {
	orderId: number;
	// items: OrderedProduct[];
	orderStatus: PaymentStatus;
}

export interface OrderAddress {
	addressId: string;
	city: string;
	country: string;
	number: string;
	optionalAddressLine: string;
	organizationName: string;
	poBox: string;
	postalCode: string;
	state: string;
	street: string;
}

export const toDealerVisibilities = visibilities => ({
	isDiscountGroupVisible: visibilities?.isDiscountGroupVisible || false,
	isListPriceSumVisible: visibilities?.isListPriceSumVisible || false,
	isSpecialOfferDiscountVisible: visibilities?.isSpecialOfferDiscountVisible || false,
	isVatVisible: visibilities?.isVatVisible || false,
});
