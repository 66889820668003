import { CurrencySymbols } from '@isp/price';

export const extractCurrencySymbol = priceString => {
	const currencySymbols = Object.values(CurrencySymbols)
		.map(symbol => symbol.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')) // Escape special characters
		.join('|');
	const regexPattern = new RegExp(`(${currencySymbols})`, 'g');
	const regex = new RegExp(regexPattern);
	const match = priceString?.match(regex);

	if (match) return match[0];
	return null;
};

export const getCurrencyFromSymbol = (currencySymbol: string) => {
	const currencyKey = Object.entries(CurrencySymbols).find(currencyEntry => currencyEntry[1] === currencySymbol);
	return currencyKey?.[0] || currencySymbol;
};

export const extractNumericValue = priceString => {
	const currencySymbols = Object.values(CurrencySymbols).join('|');
	const regexPattern = `(\\d{1,3}(?:[.,]\\d{3})*(?:[.,]\\d+)?)\\s*(${currencySymbols})?`;
	const regex = new RegExp(regexPattern);
	const match = priceString?.match(regex);

	if (match) return match[1];
	return null;
};
