import get from 'lodash/get';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import orderBy from 'lodash/orderBy';
import remove from 'lodash/remove';
import dayjs from 'dayjs';
import { formatDate, getMilliseconds } from '@Helpers/common/date';
import { DSBDetailedServiceInfoData } from '@IspTypes/vehicle/responseTypes';
import {
	CHAPTER_TYPES,
	INNER_WINDOW_HEIGHT,
	DSBServiceType,
	RECT_OFFSET,
	RECT_OFFSET_SESQUI,
	RECT_OFFSET_UP,
	SERVICE_TYPE_TRANSLATION,
	OilQuality,
	AddWork,
	AddWorkType,
	DSBServiceDocumentationDetails,
	DSBMileageType,
} from '@Constants/vehicle';
import OIL_QUALITIES from '@Constants/vehicle/OilQualities.json';
import { CustomEvents, DATE_FORMAT_DDMMYYYY } from '@Constants/common';
import { FinalVehicleType } from '@Constants/product';

export const getOilQualities = (vehicleType: FinalVehicleType): OilQuality[] => {
	const filterBy = vehicleType === FinalVehicleType.PC ? FinalVehicleType.PKW : FinalVehicleType.VAN;
	return OIL_QUALITIES.filter(item => item[filterBy]);
};

export const getServiceTypeOptions = t => [
	{
		value: DSBServiceType.SERVICE_A,
		label: t(`products:${SERVICE_TYPE_TRANSLATION[DSBServiceType.SERVICE_A]}`),
		id: 0,
	},
	{
		value: DSBServiceType.SERVICE_B,
		label: t(`products:${SERVICE_TYPE_TRANSLATION[DSBServiceType.SERVICE_B]}`),
		id: 1,
	},
	{
		value: DSBServiceType.SERVICE_INTERIM,
		label: t(`products:${SERVICE_TYPE_TRANSLATION[DSBServiceType.SERVICE_INTERIM]}`),
		id: 2,
	},
];

export const getDsbDocumentationData = formData => {
	const serviceType = get(formData, 'serviceType');

	const workshopInformationData = {
		serviceDate: get(formData, 'serviceDate'),
		jobCardNumber: get(formData, 'jobCardNumber'),
		dealerPlace: get(formData, 'companyAddress.town'),
		dealerStreet: get(formData, 'companyAddress.street'),
		dealerDescription: get(formData, 'companyAddress.name'),
	};
	const vehicleInformationData = {
		mileage: { indicator: get(formData, 'preferredDistanceUnit'), value: Number(get(formData, 'mileage')) },
	};
	const vehicleInformationDataNotInterim = {
		remainingDays: Number(get(formData, 'remainingTime')),
		remainingMileage: Number(get(formData, 'remainingDistance')),
		nextServiceMileage: Number(get(formData, 'nextServiceAt')),
		startMileage: Number(get(formData, 'mileageUntilNextService')),
		nextServiceDate: formatDate(getMilliseconds(get(formData, 'nextServiceOn'), DATE_FORMAT_DDMMYYYY)),
	};
	const serviceInformationData = {
		serviceType,
		headlampCheckExecuted: get(formData, 'headlampCheckExecuted'),
		internalComment: get(formData, 'internalComment'),
		customerComment: get(formData, 'customerComment'),
		workshopCode: get(formData, 'workshopCode') || null,
		oilDetails: getOilDetails(get(formData, 'oilQuality')),
		repairPartDetails: get(formData, 'repairPartDetails'),
		regularServiceAddWork: get(formData, 'regularServiceAddWork'),
	};

	const formDataToSend = Object.assign(
		workshopInformationData,
		vehicleInformationData,
		serviceType !== DSBServiceType.SERVICE_INTERIM && vehicleInformationDataNotInterim,
		serviceInformationData
	);

	return formDataToSend;
};

export const parseDsbDocumentationData = (vehicleData: DSBDetailedServiceInfoData): DSBServiceDocumentationDetails => {
	const {
		serviceType,
		serviceDate,
		jobCardNumber,
		mileage,
		remainingDays,
		remainingMileage,
		nextServiceMileage,
		internalComment,
		customerComment,
		dealerPlace,
		dealerStreet,
		dealerDescription,
		deliveryAddresses,
		regularServiceAddWork,
		repairPartDetails,
		oilDetails,
		workshopCode,
		startMileage,
		nextServiceDate,
		isCreatedToday,
		headlampCheckExecuted,
	} = vehicleData;

	const oilQualityCheckbox = find(repairPartDetails, item => item?.addWorkType === AddWorkType.ENGINE_OIL_FILTER);
	let sortedAddWorks = remove(
		repairPartDetails,
		(addWork: AddWork) => addWork.addWorkType === AddWorkType.ENGINE_OIL_FILTER
	);
	sortedAddWorks = orderBy(repairPartDetails, ['description']);
	sortedAddWorks.unshift(oilQualityCheckbox);

	return {
		serviceType: serviceType || null,
		serviceDate: serviceDate || '',
		jobCardNumber: jobCardNumber || '',
		deliveryAddresses: deliveryAddresses || [],
		companyAddress: {
			town: dealerPlace || '',
			street: dealerStreet || '',
			name: dealerDescription || '',
		},
		preferredDistanceUnit: mileage.indicator || DSBMileageType.KM,
		mileage: String(mileage.value) || '',
		remainingTime: String(remainingDays) || '',
		remainingDistance: String(remainingMileage) || '',
		mileageUntilNextService: String(startMileage) || '',
		nextServiceOn: nextServiceDate || '',
		nextServiceAt: String(nextServiceMileage) || '',
		internalComment: internalComment || '',
		customerComment: customerComment || '',
		workshopCode: workshopCode || '',
		oilDetails: oilDetails || null,
		repairPartDetails: sortedAddWorks,
		regularServiceAddWork: regularServiceAddWork || false,
		isCreatedToday: isCreatedToday || false,
		headlampCheckExecuted: headlampCheckExecuted || false,
	};
};

export const onModalNavigationScroll = () => {
	const workshopInformationSection = document.querySelector('[data-id=workshop-information-section]');
	const vehicleInformationSection = document.querySelector('[data-id=vehicle-information-section]');
	const serviceInformationSection = document.querySelector('[data-id=service-information-section]');

	const workshopInformationPosition = workshopInformationSection?.getBoundingClientRect();
	const vehicleInformationPosition = vehicleInformationSection?.getBoundingClientRect();
	const serviceInformationPosition = serviceInformationSection?.getBoundingClientRect();
	if (
		workshopInformationPosition &&
		workshopInformationPosition.top < window.innerHeight &&
		workshopInformationPosition.bottom >= RECT_OFFSET_UP
	) {
		return CHAPTER_TYPES.WORKSHOP_INFORMATION;
	}
	if (
		vehicleInformationPosition &&
		vehicleInformationPosition.top < window.innerHeight &&
		window.innerHeight <= INNER_WINDOW_HEIGHT
			? vehicleInformationPosition.bottom >= RECT_OFFSET_SESQUI
			: vehicleInformationPosition.bottom >= RECT_OFFSET_UP
	) {
		return CHAPTER_TYPES.VEHICLE_INFORMATION;
	}
	if (
		serviceInformationPosition &&
		serviceInformationPosition.top < window.innerHeight &&
		serviceInformationPosition.bottom >= RECT_OFFSET
	) {
		return CHAPTER_TYPES.SERVICE_INFORMATION;
	}

	return null;
};

export const handleInitialStateDateFormat = (data, format, fallback?) => {
	return data ? dayjs(data).format(format) : fallback || '';
};

const getOilDetails = oilOption => {
	const value = get(oilOption, 'value');
	const oilQualities = OIL_QUALITIES.find(oilType => oilType.value === value);
	return oilQualities ? oilQualities.details : [];
};

export const getInitialOilQualityState = serviceDocumentationForm => {
	return get(serviceDocumentationForm, 'oilDetails')
		? get(serviceDocumentationForm, 'oilDetails').map(oilQuality => {
				return { value: oilQuality.oilQuality, label: oilQuality.oilQuality };
		  })[0]
		: null;
};

export const getInitialWorkshopNotAvailableState = serviceDocumentationForm => {
	return !isNil(get(serviceDocumentationForm, 'workshopCode'))
		? get(serviceDocumentationForm, 'workshopCode') === ''
		: false;
};

export const postDSBLoadEvent = success => {
	if (typeof window !== 'undefined')
		window.dispatchEvent(new CustomEvent(CustomEvents.DSB_LOAD, { detail: { success } }));
};

export const onDSBLoadEvent = callback => {
	if (typeof window !== 'undefined') {
		window.addEventListener(
			CustomEvents.DSB_LOAD,
			(e: CustomEvent) => {
				if (e.detail.success) {
					callback();
				}
			},
			{ once: true }
		);
	}
};
