import { actionTypes } from '@Reducers/modals/actionTypes';
import { System } from '@Reducers/user/models';
import {
	Part,
	FasterAvailablePart,
	PartSearchRelatedPackages,
	ToBeDeletedVehicleInformation,
	PartColorCodeInfo,
	PartColorOptionsInfo,
	PartAlternativesInfo,
	ModelRangeSelectionModalProps,
	FreeTextSearchAllResult,
} from '@Reducers/vehicle/models';
import { BannerNotificationData } from '@Umbraco/notifications';
import { WebpartsBulkOrderItem } from '@Apis/shop';
import { XWISDocumentPropType } from '@Shared/webparts/Catalogue/CatalogueModals/XWISDocumentModal';
import { RecommendedPartProps } from '@Shared/webparts/Catalogue/CatalogueModals/RecommendedPartsModal';
import { ReplacementChainModalProps } from '@Shared/webparts/Catalogue/CatalogueModals/ReplacementChainModal';
import { VehicleCatalogSelectionOptions } from '@Shared/webparts/Catalogue/CatalogueModals/CatalogSelectionModal';
import { NonPartsBasket, PartSubBasket, PartsBasket } from '@Reducers/shop/models';
import { AggregateNumber } from '@Reducers/catalogue/models';
import { Retailer } from '@Reducers/organization/models';
import { LoginErrorProps } from '@Shared/modals/PublicLoginError';
import { OrderNotification } from '@Constants/notifications';
import {
	FOCode,
	VehicleMoreInfoType,
	SearchTermType,
	ServiceDocumentationModalType,
	CatalogAggregate,
	AggregateOption,
	DSBMileageType,
} from '@Constants/vehicle';
import { OrderPageLevel, OrderType } from '@Constants/orders';
import { PARTS_BASKET_CATEGORIES } from '@Constants/shop';

export const TOGGLE_IMPRINT_MODAL = (activeTab = null) => dispatch => {
	dispatch({
		type: actionTypes.TOGGLE_IMPRINT_MODAL,
		showImprintModal: activeTab,
	});
};

export const TOGGLE_DEALER_LOCATOR = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_DEALER_LOCATOR,
		showDealerLocator: !getState().modals.showDealerLocator,
	});
};

export const TOGGLE_NOTIFICATIONS_MENU = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_NOTIFICATIONS_MENU,
		showNotificationsMenu: !getState().modals.showNotificationsMenu,
	});
};

export const TOGGLE_ACCESS_REQUEST_SUCCESS = (toggle: boolean) => dispatch => {
	dispatch({
		type: actionTypes.TOGGLE_ACCESS_REQUEST_SUCCESS,
		showAccessRequestSuccessModal: toggle,
	});
};

export const TOGGLE_ACCESS_REQUEST = (toggle: boolean) => dispatch => {
	dispatch({
		type: actionTypes.TOGGLE_ACCESS_REQUEST,
		showAccessRequestModal: toggle,
	});
};

export const TOGGLE_ORDER_RATING_MODAL = (orderName = '', pageLevel = OrderPageLevel.VEHICLE_OVERVIEW_ORDERS) => (
	dispatch,
	getState
) => {
	dispatch({
		type: actionTypes.TOGGLE_ORDER_RATING_MODAL,
		orderNameOnRatingModal: orderName,
		orderPageLevel: pageLevel,
		showOrderRatingModal: !getState().modals.showOrderRatingModal,
	});
};

export const TOGGLE_ORDER_DETAIL_MODAL = (order?: OrderNotification, modalType?: OrderType) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_ORDER_DETAIL_MODAL,
		showOrderDetailModal: !getState().modals.showOrderDetailModal,
		orderDetailModalOrder: order || null,
		orderDetailModalType: modalType || '',
	});
};

export const TOGGLE_PARTS_ORDER_DETAIL_MODAL = (partsOrderId: string = null) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_PARTS_ORDER_DETAIL_MODAL,
		showPartsOrderDetailsModal: !getState().modals.showPartsOrderDetailsModal,
		partsOrderId,
	});
};

export const TOGGLE_DSB_DETAIL_MODAL = service => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_DSB_DETAIL_MODAL,
		showDSBDetailModal: !getState().modals.showDSBDetailModal,
		selectedService: service,
	});
};

export const TOGGLE_CHANGE_HARDWARE_ID_MODAL = (
	toggle: boolean,
	selectedProductKey?: '',
	system?: System
) => dispatch => {
	dispatch({
		type: actionTypes.TOGGLE_CHANGE_HARDWARE_ID_MODAL,
		showChangeHardwareIdModal: toggle,
		system,
		selectedProductKey,
	});
};

export const TOGGLE_USERCENTRICS_BANNER_MODAL = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_USERCENTRICS_BANNER_MODAL,
		showUsercentricsBanner: !getState().modals.showUsercentricsBanner,
	});
};

export const TOGGLE_ORDER_SUCCESS_MODAL = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_ORDER_SUCCESS_MODAL,
		showOrderSuccessModal: !getState().modals.showOrderSuccessModal,
	});
};

export const TOGGLE_ORDER_WITHOUT_PAYMENT_STATUS_MODAL = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_ORDER_WITHOUT_PAYMENT_STATUS_MODAL,
		showOrderWithoutPaymentStatusModal: !getState().modals.showOrderWithoutPaymentStatusModal,
	});
};

export const TOGGLE_SERVICE_DOCUMENTATION_MODAL = (
	modalType: ServiceDocumentationModalType = null,
	toggle: boolean | null = null
) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_SERVICE_DOCUMENTATION_MODAL,
		showServiceDocumentationModal: toggle === null ? !getState().modals.showServiceDocumentationModal : toggle,
		serviceDocumentationModalType: modalType,
	});
};
export const TOGGLE_CHANGE_DISTANCE_UNIT = (userDistancePreference?: DSBMileageType) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_CHANGE_DISTANCE_UNIT,
		showChangeDistanceUnitModal: !getState().modals.showChangeDistanceUnitModal,
		userDistancePreference,
	});
};

export const TOGGLE_ADDITIONAL_INFO_NOTIFICATION = (informationForNotification?: JSX.Element) => (
	dispatch,
	getState
) => {
	dispatch({
		type: actionTypes.TOGGLE_ADDITIONAL_INFO_NOTIFICATION,
		showAdditionalInfoModal: !getState().modals.showAdditionalInfoModal,
		informationForNotification,
	});
};

export const TOGGLE_ADD_VEHICLE_MODAL = (highlightedVehicleVIN = null, VINEditDisabled = false) => (
	dispatch,
	getState
) => {
	dispatch({
		type: actionTypes.TOGGLE_ADD_VEHICLE_MODAL,
		showAddVehicleModal: !getState().modals.showAddVehicleModal,
		highlightedVehicleVIN,
		VINEditDisabled,
	});
};

export const TOGGLE_DELETE_VEHICLE_MODAL = (vehicleInformation: ToBeDeletedVehicleInformation = null) => (
	dispatch,
	getState
) => {
	dispatch({
		type: actionTypes.TOGGLE_DELETE_VEHICLE_MODAL,
		showDeleteVehicleModal: !getState().modals.showDeleteVehicleModal,
		vehicleInfoToBeDeleted: vehicleInformation,
	});
};

export const TOGGLE_INITIAL_REGISTRATION_MODAL = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_INITIAL_REGISTRATION_MODAL,
		showInitialRegistrationModal: !getState().modals.showInitialRegistrationModal,
	});
};

export const TOGGLE_EXPORT_PDF_MODAL = (selectedServicesIds = [], option = false) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_EXPORT_PDF_MODAL,
		showExportPDFOptionsModal: !getState().modals.showExportPDFOptionsModal,
		selectedServiceIdsForPDFExport: selectedServicesIds,
		showExportPDFSelectionOptions: option,
	});
};

export const OPEN_REGISTRATION = () => dispatch => {
	dispatch({
		type: actionTypes.OPEN_REGISTRATION,
		showRegistrationModal: true,
	});
};

export const CLOSE_REGISTRATION = () => dispatch => {
	dispatch({
		type: actionTypes.CLOSE_REGISTRATION,
		showRegistrationModal: false,
	});
};

export const TOGGLE_USERCENTRICS_SETTINGS_MODAL = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_USERCENTRICS_SETTINGS_MODAL,
		showUsercentricsSettingsModal: !getState().modals.showUsercentricsSettingsModal,
	});
};

export const TOGGLE_USERCENTRICS_INFORMATION_MODAL = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_USERCENTRICS_INFORMATION_MODAL,
		showUsercentricsInformationModal: !getState().modals.showUsercentricsInformationModal,
	});
};

export const TOGGLE_MORE_INFO_MODAL = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_MORE_INFO_MODAL,
		showMoreInfoModal: !getState().modals.showMoreInfoModal,
	});
};

export const TOGGLE_REMAN_PARTS_MODAL = (searchTerm = '', response = null, searchTermType = SearchTermType.NOT_SET) => (
	dispatch,
	getState
) => {
	dispatch({
		type: actionTypes.TOGGLE_REMAN_PARTS_MODAL,
		showRemanPartsModal: !getState().modals.showRemanPartsModal,
		searchTerm,
		response,
		searchTermType,
	});
};

export const TOGGLE_REMAN_PARTS_REQUEST_MODAL = (content, isThereRelation?) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_REMAN_PARTS_REQUEST_MODAL,
		showRemanPartsRequestModal: !getState().modals.showRemanPartsRequestModal,
		remanPartsRequestProduct: content,
		isThereRelation,
	});
};

export const TOGGLE_FAST_AVAILABILITY_MODAL = (selectedFasterAvailablePart: FasterAvailablePart) => (
	dispatch,
	getState
) => {
	dispatch({
		type: actionTypes.TOGGLE_FAST_AVAILABILITY_MODAL,
		showFasterAvailableModal: !getState().modals.showFasterAvailableModal,
		selectedFasterAvailablePart,
	});
};

export const TOGGLE_COLOR_SELECTION_MODAL = (selectedPartColorInfo?: PartColorOptionsInfo) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_COLOR_SELECTION_MODAL,
		showColorSelectionModal: !getState().modals.showColorSelectionModal,
		selectedPartColorInfo,
	});
};

export const TOGGLE_VEHICLE_MORE_INFO_MODAL = (
	moreInfoType: VehicleMoreInfoType = VehicleMoreInfoType.NOT_SET,
	vehicleMoreInfoModalData = null
) => dispatch => {
	dispatch({
		type: actionTypes.TOGGLE_VEHICLE_MORE_INFO_MODAL,
		vehicleMoreInfoType: moreInfoType,
		vehicleMoreInfoModalData,
	});
};

export const TOGGLE_VEHICLE_EDIT_MODAL = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_VEHICLE_EDIT_MODAL,
		showVehicleEditModal: !getState().modals.showVehicleEditModal,
	});
};

export const TOGGLE_MEGA_MENU = (options = { productInformation: true }) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_MEGA_MENU,
		showMegaMenu: !getState().modals.showMegaMenu,
		megaMenuOptions: options,
	});
};

export const TOGGLE_COLOR_CODE_INFO_MODAL = (selectedPartColorCodeInfo: PartColorCodeInfo) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_COLOR_CODE_INFO_MODAL,
		showColorCodeInfoModal: !getState().modals.showColorCodeInfoModal,
		selectedPartColorCodeInfo,
	});
};

export const TRIGGER_CATALOG_OPTION_SELECTION_MODAL = (
	selectedVehicleCatalogSelectionOptions: VehicleCatalogSelectionOptions = null
) => dispatch => {
	dispatch({
		type: actionTypes.TRIGGER_CATALOG_OPTION_SELECTION_MODAL,
		// If catalog selection options exist open modal if it is not close modal
		showCatalogOptionSelectionModal: selectedVehicleCatalogSelectionOptions !== null,
		selectedVehicleCatalogSelectionOptions,
	});
};

export const TOGGLE_CATALOGUE_FILTER_MODAL = (showCatalogueFilterModal = false) => dispatch => {
	dispatch({
		type: actionTypes.TOGGLE_CATALOGUE_FILTER_MODAL,
		showCatalogueFilterModal,
	});
};

export const TOGGLE_ALTERNATE_PART_MODAL = (selectedPartAlternativesInfo: PartAlternativesInfo = null) => dispatch => {
	dispatch({
		type: actionTypes.TOGGLE_ALTERNATE_PART_MODAL,
		showAlternatePartModal: selectedPartAlternativesInfo !== null,
		selectedPartAlternativesInfo,
	});
};

export const TOGGLE_REPLACEMENT_CHAIN_MODAL = (
	selectedReplacementChainPart: ReplacementChainModalProps = null
) => dispatch => {
	dispatch({
		type: actionTypes.TOGGLE_REPLACEMENT_CHAIN_MODAL,
		showReplacementChainModal: selectedReplacementChainPart !== null,
		selectedReplacementChainPart,
	});
};

export const TOGGLE_COMPANY_DATA_EDIT_MODAL = (organizationInfo = null, organizationName = null) => (
	dispatch,
	getState
) => {
	dispatch({
		type: actionTypes.TOGGLE_COMPANY_DATA_EDIT_MODAL,
		organizationInfo,
		organizationName,
		showCompanyDataModal: !getState().modals.showCompanyDataModal,
	});
};

export const TOGGLE_REQUEST_CREATED_MODAL = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_REQUEST_CREATED_MODAL,
		showRequestCreatedModal: !getState().modals.showRequestCreatedModal,
	});
};

export const TOGGLE_PART_PACKAGE_MODAL = (selectedPartPackageInfo: PartSearchRelatedPackages) => (
	dispatch,
	getState
) => {
	dispatch({
		type: actionTypes.TOGGLE_PART_PACKAGE_MODAL,
		showPartPackageModal: !getState().modals.showPartPackageModal,
		selectedPartPackageInfo,
	});
};

export const TOGGLE_FREE_TEXT_SEARCH_MODAL = (selectedVehicleAndText: FreeTextSearchAllResult) => (
	dispatch,
	getState
) => {
	dispatch({
		type: actionTypes.TOGGLE_FREE_TEXT_SEARCH_MODAL,
		showFreeTextSearchModal: !getState().modals.showFreeTextSearchModal,
		selectedVehicleAndText,
	});
};

export const TOGGLE_MODEL_RANGE_SELECTION_MODAL = (
	modelRangeSelectionModalProps: ModelRangeSelectionModalProps = null
) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_MODEL_RANGE_SELECTION_MODAL,
		showModelRangeSelectionModal: !getState().modals.showModelRangeSelectionModal,
		modelRangeSelectionModalProps,
	});
};

export const TOGGLE_FOOTNOTE_MODAL = (selectedFootnoteItem: Part) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_FOOTNOTE_MODAL,
		showFootnoteModal: !getState().modals.showFootnoteModal,
		selectedFootnoteItem,
	});
};

export const TOGGLE_BANNER_NOTIFICATION_MODAL = (
	selectedBannerNotification: BannerNotificationData = null
) => dispatch => {
	dispatch({
		type: actionTypes.TOGGLE_BANNER_NOTIFICATION_MODAL,
		showNotificationBannerModal: selectedBannerNotification !== null,
		selectedBannerNotification,
	});
};

export const TOGGLE_NEW_FEATURES_MODAL = (toggle = null) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_NEW_FEATURES_MODAL,
		showNewFeaturesModal: toggle !== null ? toggle : !getState().modals.showNewFeaturesModal,
	});
};

export const TOGGLE_BULK_ORDER_MODAL = (bulkOrderResult: WebpartsBulkOrderItem[]) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_BULK_ORDER_MODAL,
		showBulkOrderModal: !getState().modals.showBulkOrderModal,
		bulkOrderResult,
	});
};

export const TOGGLE_RETAILER_OPENING_HOURS_MODAL = (selectedApprovedDealerId = '', selectedApprovedDealerName = '') => (
	dispatch,
	getState
) => {
	dispatch({
		type: actionTypes.TOGGLE_RETAILER_OPENING_HOURS_MODAL,
		showOpeningHoursModal: !getState().modals.showOpeningHoursModal,
		selectedApprovedDealerId,
		selectedApprovedDealerName,
	});
};

export const TOGGLE_LIMITED_ADMIN_ACCESS_MODAL = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_LIMITED_ADMIN_ACCESS_MODAL,
		showLimitedAdminAccessModal: !getState().modals.showLimitedAdminAccessModal,
	});
};

export const TOGGLE_UNLOCK_VIA_PPV_MODAL = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_UNLOCK_VIA_PPV_MODAL,
		showUnlockViaPPVModal: !getState().modals.showUnlockViaPPVModal,
	});
};

export const TOGGLE_CONTENT_REPORTING_MODAL = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_CONTENT_REPORTING_MODAL,
		showContentReportingModal: !getState().modals.showContentReportingModal,
	});
};

export const TOGGLE_PUBLIC_SUPPORT_MODAL = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_PUBLIC_SUPPORT_MODAL,
		showPublicSupportModal: !getState().modals.showPublicSupportModal,
	});
};

export const TOGGLE_SPECIAL_MODIFICATION_VEHICLE_MODAL = (toggle: boolean = null, foCodes: FOCode[] = null) => (
	dispatch,
	getState
) => {
	dispatch({
		type: actionTypes.TOGGLE_SPECIAL_MODIFICATION_VEHICLE_MODAL,
		showSpecialModificationVehicleModal: toggle ?? !getState().modals.showSpecialModificationVehicleModal,
		foCodesForLastAddedVehicle: foCodes,
	});
};

export const TOGGLE_AGGREGATE_NUMBER_SELECTION_MODAL = (
	aggregate: CatalogAggregate = null,
	aggregateOption: AggregateNumber | AggregateOption
) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_AGGREGATE_NUMBER_SELECTION_MODAL,
		showAggregateNumberSelectionModal: !getState().modals.showAggregateNumberSelectionModal,
		aggregate,
		aggregateOption,
	});
};

export const TOGGLE_BASKET_BULK_DELETE_CONFIRMATION_MODAL = (
	basket?: NonPartsBasket | PartsBasket | PartSubBasket,
	partsBasketCategory?: PARTS_BASKET_CATEGORIES
) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_BASKET_BULK_DELETE_CONFIRMATION_MODAL,
		showBasketDeleteConfirmationModal: !getState().modals.showBasketDeleteConfirmationModal,
		selectedBasketForBulkDelete: basket,
		selectedCategoryForBulkDelete: partsBasketCategory,
	});
};

export const TOGGLE_RETAILER_DELETE_CONFIRMATION_MODAL = (retailer: Retailer) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_RETAILER_DELETE_CONFIRMATION_MODAL,
		showRetailerDeleteConfirmationModal: !getState().modals.showRetailerDeleteConfirmationModal,
		selectedRetailerForDelete: retailer,
	});
};

export const TOGGLE_ADD_BASKET_DIRECT_MODAL = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_ADD_BASKET_DIRECT_MODAL,
		showAddBasketDirectlyModal: !getState().modals.showAddBasketDirectlyModal,
	});
};

export const TOGGLE_ERROR_MODAL = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_ERROR_MODAL,
		showErrorModal: !getState().modals.showErrorModal,
	});
};

export const TOGGLE_LOGIN_ERROR_MODAL = (loginErrorProps?: LoginErrorProps) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_LOGIN_ERROR_MODAL,
		loginErrorProps,
		showLoginErrorModal: !getState().modals.showLoginErrorModal,
	});
};

export const TOGGLE_ADD_OLD_VEHICLE_CONFIRM_MODAL = (vin?: string, saveVehicle?: Function) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_ADD_OLD_VEHICLE_CONFIRM_MODAL,
		showAddOldVehicleConfirmModal: !getState().modals.showAddOldVehicleConfirmModal,
		addOldVehicleConfirmModalVin: vin,
		addOldVehicleConfirmModalSaveVehicle: saveVehicle,
	});
};

export const TOGGLE_MODEL_IDENTIFICATION_MODAL = (searchTerm = '') => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_MODEL_IDENTIFICATION_MODAL,
		showModelIdentificationModal: !getState().modals.showModelIdentificationModal,
		catalogPartSearchTerm: searchTerm,
	});
};

export const TOGGLE_LUCKY_DRAW_MODAL = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_LUCKY_DRAW_MODAL,
		showLuckyDrawModal: !getState().modals.showLuckyDrawModal,
	});
};

export const TOGGLE_LUCKY_DRAW_PARTICIPATION_SUCCESS_MODAL = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_LUCKY_DRAW_PARTICIPATION_SUCCESS_MODAL,
		showLuckyDrawParticipationSuccessModal: !getState().modals.showLuckyDrawParticipationSuccessModal,
	});
};

export const TOGGLE_NO_ACTIVE_LUCKY_DRAW_MODAL = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_NO_ACTIVE_LUCKY_DRAW_MODAL,
		showNoActiveLuckyDrawModal: !getState().modals.showNoActiveLuckyDrawModal,
	});
};

export const SET_SHOW_PART_XWIS_DOCUMENT_MODAL = (xwisDocumentPartProps: XWISDocumentPropType = null) => dispatch => {
	dispatch({
		type: actionTypes.SET_SHOW_PART_XWIS_DOCUMENT_MODAL,
		xwisDocumentPartProps,
	});
};

export const SET_RECOMMEDED_PART_MODAL = (recommendedPartProps: RecommendedPartProps = null) => dispatch => {
	dispatch({
		type: actionTypes.SET_RECOMMEDED_PART_MODAL,
		recommendedPartProps,
	});
};

export const SET_ADD_COMPANY_MAIL_MODAL_VISIBILITY = (showAddCompanyMailModal = false) => dispatch => {
	dispatch({
		type: actionTypes.SET_ADD_COMPANY_MAIL_MODAL_VISIBILITY,
		showAddCompanyMailModal,
	});
};

export const SET_ADD_NEW_PARTNER_MODAL_VISIBILITY = (showAddNewPartnerModal = false) => dispatch => {
	dispatch({
		type: actionTypes.SET_ADD_NEW_PARTNER_MODAL_VISIBILITY,
		showAddNewPartnerModal,
	});
};
