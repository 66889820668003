import cN from 'classnames';
import { VideoPlayer } from '@isp/video-player';
import { isVideo } from '@Helpers/common/media';

export const Media = ({ media, language, className = '' }) => {
	const isMediaVideo = isVideo(media.url);

	return (
		<div className={cN('disp-grid train', className)}>
			{!isMediaVideo && <img src={media.url} alt={media.altText || 'B2B Connect Media'} />}
			{isMediaVideo && <VideoPlayer key={language} src={media.url} />}
		</div>
	);
};
