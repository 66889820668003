import { IconType, IconVariant } from '@isp/icon';
import { DeliveryTypes } from '@Reducers/shop/models';

export enum AccessiblePlatforms {
	WEBPARTS = 'WEBPARTS',
	ADDITIONAL_PURCHASE_INFORMATION = 'ADDITIONAL_PURCHASE_INFORMATION',
}

export enum OrderStockType {
	STOCK_ORDER = 'STOCK_ORDER',
	IMMEDIATE_REQUIREMENT = 'IMMEDIATE_REQUIREMENT',
	VEHICLE_OFF_ROAD = 'VEHICLE_OFF_ROAD',
}

export const StockTypeTranslation = {
	[OrderStockType.STOCK_ORDER]: 'account:order-type-stock',
	[OrderStockType.IMMEDIATE_REQUIREMENT]: 'account:order-type-immediate',
	[OrderStockType.VEHICLE_OFF_ROAD]: '',
};

export const DeliveryTypeTranslation = {
	[DeliveryTypes.COLLECTION]: 'orders:collection',
	[DeliveryTypes.DELIVERY]: 'orders:delivery',
	[DeliveryTypes.COLLECTION_STATION]: 'orders:collection-station',
};

export enum AccessStates {
	PENDING = 'PENDING',
	NO_ACCESS = 'NO_ACCESS',
	ACTIVE = 'ACTIVE',
	PARTIALLY_ACTIVE = 'PARTIALLY_ACTIVE',
}

export const INITIAL_WEBPART_PROPERTIES = {
	name: 'account:webparts',
	description: 'account:webparts-description',
};

export const INITIAL_XENTRY_PROPERTIES = {
	name: 'account:additional-purchase-auth-title',
};

export enum OrganizationTypes {
	FREE_WORKSHOP = 'FREE_WORKSHOP',
	PUBLISHER = 'PUBLISHER',
	TRAINING_FACILITY = 'TRAINING_FACILITY',
	FLEET = 'FLEET',
	AUTHORITIES = 'AUTHORITIES',
	ISP_WITHOUT_WS = 'ISP_WITHOUT_WS',
	OTHER = 'OTHER',
}

export const WEBPART_PROPERTIES = {
	[AccessStates.ACTIVE]: { iconType: IconType.LINK, iconVariant: IconVariant.BLUE },
	[AccessStates.PENDING]: {
		iconType: IconType.CLOCK,
		iconVariant: IconVariant.GREYISHBROWN,
		status: 'account:webparts-approval-pending',
	},
	[AccessStates.NO_ACCESS]: {
		iconType: IconType.ERROR2,
		iconVariant: IconVariant.BLOODRED,
		requestMessage: 'account:webparts-request',
	},
	[AccessStates.PARTIALLY_ACTIVE]: {
		iconType: IconType.ERROR2,
		iconVariant: IconVariant.BLOODRED,
		status: 'account:webparts-request',
	},
};

export const XENTRY_PROPERTIES = {
	[AccessStates.ACTIVE]: {
		iconType: IconType.LINK,
		iconVariant: IconVariant.BLUE,
		description: 'account:additional-purchase-auth-granted-description',
	},
	[AccessStates.PARTIALLY_ACTIVE]: {
		iconType: IconType.ERROR2,
		iconVariant: IconVariant.BLOODRED,
		description: 'account:additional-purchase-auth-expired-description',
		requestMessage: 'account:additional-purchase-auth-request',
	},
	[AccessStates.PENDING]: {
		iconType: IconType.ERROR2,
		iconVariant: IconVariant.BLOODRED,
		description: 'account:xentry-shop-description',
		name: 'account:xentry-shop',
	},
	[AccessStates.NO_ACCESS]: {
		iconType: IconType.ERROR2,
		iconVariant: IconVariant.BLOODRED,
		name: 'account:xentry-shop',
		description: 'account:xentry-shop-description',
		requestMessage: 'account:xentry-shop-request',
	},
};

export enum FileTypes {
	EXE = 'exe',
	COM = 'com',
	BAT = 'bat',
	JS = 'js',
	MSI = 'msi',
	JAR = 'jar',
	CMD = 'cmd',
	HTML = 'html',
	EML = 'eml',
	SEVEN_Z = '7z',
	ARR = 'arr',
	CSV = 'csv',
	CONF = 'conf',
	DOC = 'doc',
	DOCM = 'docm',
	DOCX = 'docx',
	DOTX = 'dotx',
	GIF = 'gif',
	HTM = 'htm',
	INI = 'ini',
	JPG = 'jpg',
	JPEG = 'jpeg',
	LIC = 'lic',
	LOG = 'log',
	MHT = 'mht',
	MSG = 'msg',
	ODT = 'odt',
	ODS = 'ods',
	ODP = 'odp',
	PNG = 'png',
	PDF = 'pdf',
	PPT = 'ppt',
	PPTX = 'pptx',
	RTF = 'rtf',
	SQL = 'sql',
	TXT = 'txt',
	VSD = 'vsd',
	VSDX = 'vsdx',
	XLSM = 'xlsm',
	XLS = 'xls',
	XLSX = 'xlsx',
	XLT = 'xlt',
	XML = 'xml',
	XPS = 'xps',
	ZIP = 'zip',
	MP4 = 'mp4',
}

export const MAX_NUMBER_OF_FILES = 3;
export const MAX_NUMBER_OF_BYTES = 10485760;
export const CREDITS_DEFAULT_PAGE_SIZE = 10;

export const ATTACHMENT_ALLOWED_FILE_TYPES: string[] = [
	FileTypes.SEVEN_Z,
	FileTypes.ARR,
	FileTypes.CSV,
	FileTypes.CONF,
	FileTypes.DOC,
	FileTypes.DOCM,
	FileTypes.DOCX,
	FileTypes.DOTX,
	FileTypes.GIF,
	FileTypes.INI,
	FileTypes.JPG,
	FileTypes.JPEG,
	FileTypes.LIC,
	FileTypes.LOG,
	FileTypes.MHT,
	FileTypes.MSG,
	FileTypes.ODT,
	FileTypes.ODS,
	FileTypes.ODP,
	FileTypes.PNG,
	FileTypes.PDF,
	FileTypes.PPT,
	FileTypes.PPTX,
	FileTypes.RTF,
	FileTypes.SQL,
	FileTypes.TXT,
	FileTypes.VSD,
	FileTypes.VSDX,
	FileTypes.XLSM,
	FileTypes.XLS,
	FileTypes.XLSX,
	FileTypes.XLT,
	FileTypes.XML,
	FileTypes.XPS,
	FileTypes.ZIP,
	FileTypes.MP4,
];

export const OrganizationBusinessTypeMapping = {
	[OrganizationTypes.FREE_WORKSHOP]: 'account:independent-workshop',
	[OrganizationTypes.PUBLISHER]: 'account:republisher',
	[OrganizationTypes.TRAINING_FACILITY]: 'account:training-facility',
	[OrganizationTypes.FLEET]: 'account:fleet-customer',
	[OrganizationTypes.AUTHORITIES]: 'account:authorities',
};

export const IFRAME_INITIAL_HEIGHT = 2000;
export const SPA_COMPLETED_CALLBACK = 'spaCompletedCallback';
export const SPA_ERROR_CALLBACK = 'spaErrorCallback';
export const SPA_SKIPPED_CALLBACK = 'spaSkippedCallback';
export const SET_IFRAME_HEIGHT = 'setIframeHeight';
export const CONSENT_SKIPPED_DATA_UPSERT = 'CONSENT_SKIPPED_DATA_UPSERT';
export const MAX_LENGTH_OF_USERNAME = 16;

export enum SpaConstants {
	ISP_CONNECT_SYSTEM = 'ISPconnect (MASTER)',
	DOC_ONLINE_OPT_TIER_2 = 'ONLINE-OPT_IN-TIER2',
	ONEWEB_THEME = 'oneweb',
	CIAM = 'CIAM',
	PHONE_TYPE_MOBILE = 'M',
	PHONE_TYPE_LANDLINE = 'L',
}

export enum SpaPhoneType {
	LANDLINE = 'L',
	MOBILE = 'M',
	FAX = 'F',
}

export enum SpaParams {
	MARKET = 'm',
	LANGUAGE_CODE = 'l',
	CONSENT_VARIANT = 'vari',
	LOCATION = 'loc',
	LEGAL_ENTITY = 'le',
	CIAM_ID = 'ciam',
	CAMPAIGN_ID = 'cpid',
	DIALOG_SUGGESTION = 'ds',
	SKIP_BUTTON_INVISIBLE = 'bcd',
	CANCEL_BUTTON_INVISIBLE = 'bcd2',
	NEXT_BUTTON_LABEL = 'bcl1',
	SKIP_BUTTON_LABEL = 'bcl2',
	SEND_CONFIRMATION_ON_EMAIL = 'ce',
	THEME_NAME = 'theme',
	SYSTEM = 's',
	CALLBACK_URL = 'cb',
	URL_FRAGMENT_CIAM_ID = 'ciamid',
	CUSTOMER_TYPE = 'ct',
	PERSONAL_INFORMATION_TYPE = 'at',
	SALUTATION = 'cs',
	ACADEMIC_TITLE = 'cat',
	INITIALS = 'pni',
	FIRST_NAME = 'pnf',
	MIDDLE_NAME = 'pnm',
	LINK_NAME = 'pnlk',
	LAST_NAME = 'pnl',
	SECOND_NAME = 'pnl2',
	SUFFIX = 'pns',
	COMPANY_NAME_1 = 'cn1',
	COMPANY_NAME_2 = 'cn2',
	SUPPLEMENT = 'nsp',
	EXTERNAL_KEY_PROVIDER = 'ekp',
	EXTERNAL_KEY_VALUE = 'ekv',
	PHONE_NUMBER = 'tn',
	PHONE_TYPE = 'tt',
	MAIL_ADDRESS = 'ea',
	POSTAL_ADDRESS_ORGANISATION = 'ao',
	STREET = 'as',
	STREET_TYPE = 'ast',
	HOUSE_NUMBER = 'ah',
	HOUSE_NAME = 'ahouse',
	DOOR_NUMBER = 'adn',
	FLOOR_NUMBER = 'afn',
	POSTAL_CODE = 'azip',
	LOCALITY = 'al',
	STATE = 'astate',
	PROVINCE = 'aprov',
	COUNTRY = 'country',
}

export enum Languages {
	DE = 'de',
	EN = 'en',
	EN_AU = 'en-AU',
	EN_TW = 'en-TW',
	RO = 'ro',
	CS = 'cs',
	DA = 'da',
	EL = 'el',
	ES = 'es',
	BE = 'be',
	FI = 'fi',
	FR = 'fr',
	IN = 'in',
	IT = 'it',
	KO = 'ko',
	NL = 'nl',
	NO = 'no',
	PL = 'pl',
	PT = 'pt',
	SV = 'sv',
	TR = 'tr',
	ZH = 'zh',
	ZH_HK = 'zh-HK',
	ZH_TW = 'zh-TW',
	HU = 'hu',
	SK = 'sk',
}
