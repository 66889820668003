import { CatalogueGroupTypes } from '@Constants/webparts';

export const getPartResultFields = partResult => {
	const redirectionInfo = (partResult?.navContext || partResult?.navContext) ?? [];
	return {
		mainGroup: redirectionInfo.find(group => group.type === CatalogueGroupTypes.MAIN_GROUP),
		subGroup: redirectionInfo.find(group => group.type === CatalogueGroupTypes.SUB_GROUP),
		description: partResult?.description ?? null,
		materialDescription: partResult?.materialDescription ?? null,
		partNumber: partResult?.partNo ?? null,
	};
};
