export const actionTypes = {
	TOGGLE_IMPRINT_MODAL: 'TOGGLE_IMPRINT_MODAL',
	TOGGLE_DEALER_LOCATOR: 'TOGGLE_DEALER_LOCATOR',
	TOGGLE_NOTIFICATIONS_MENU: 'TOGGLE_NOTIFICATIONS_MENU',
	OPEN_REGISTRATION: 'OPEN_REGISTRATION',
	CLOSE_REGISTRATION: 'CLOSE_REGISTRATION',
	SET_SHOW_PART_XWIS_DOCUMENT_MODAL: 'SET_SHOW_PART_XWIS_DOCUMENT_MODAL',
	SET_RECOMMEDED_PART_MODAL: 'SET_RECOMMEDED_PART_MODAL',
	SET_ADD_NEW_PARTNER_MODAL_VISIBILITY: 'SET_ADD_NEW_PARTNER_MODAL_VISIBILITY',
	SET_ADD_COMPANY_MAIL_MODAL_VISIBILITY: 'SET_ADD_COMPANY_MAIL_MODAL_VISIBILITY',
	TOGGLE_ACCESS_REQUEST_SUCCESS: 'CLOSE_ACCESS_REQUEST_SUCCESS',
	TOGGLE_ACCESS_REQUEST: 'TOGGLE_ACCESS_REQUEST',
	TOGGLE_ORDER_RATING_MODAL: 'TOGGLE_ORDER_RATING_MODAL',
	TOGGLE_ORDER_DETAIL_MODAL: 'TOGGLE_ORDER_DETAIL_MODAL',
	TOGGLE_PARTS_ORDER_DETAIL_MODAL: 'TOGGLE_PARTS_ORDER_DETAIL_MODAL',
	TOGGLE_DSB_DETAIL_MODAL: 'TOGGLE_DSB_DETAIL_MODAL',
	TOGGLE_CHANGE_HARDWARE_ID_MODAL: 'TOGGLE_CHANGE_HARDWARE_ID_MODAL',
	TOGGLE_USERCENTRICS_BANNER_MODAL: 'TOGGLE_USERCENTRICS_BANNER_MODAL',
	TOGGLE_ORDER_SUCCESS_MODAL: 'TOGGLE_ORDER_SUCCESS_MODAL',
	TOGGLE_SERVICE_DOCUMENTATION_MODAL: 'TOGGLE_SERVICE_DOCUMENTATION_MODAL',
	TOGGLE_CHANGE_DISTANCE_UNIT: 'TOGGLE_CHANGE_DISTANCE_UNIT',
	TOGGLE_ADD_VEHICLE_MODAL: 'TOGGLE_ADD_VEHICLE_MODAL',
	TOGGLE_DELETE_VEHICLE_MODAL: 'TOGGLE_DELETE_VEHICLE_MODAL',
	TOGGLE_INITIAL_REGISTRATION_MODAL: 'TOGGLE_INITIAL_REGISTRATION_MODAL',
	TOGGLE_EXPORT_PDF_MODAL: 'TOGGLE_EXPORT_PDF_MODAL',
	TOGGLE_ORDER_WITHOUT_PAYMENT_STATUS_MODAL: 'TOGGLE_ORDER_WITHOUT_PAYMENT_STATUS_MODAL',
	TOGGLE_USERCENTRICS_SETTINGS_MODAL: 'TOGGLE_USERCENTRICS_SETTINGS_MODAL',
	TOGGLE_USERCENTRICS_INFORMATION_MODAL: 'TOGGLE_USERCENTRICS_INFORMATION_MODAL',
	TOGGLE_MORE_INFO_MODAL: 'TOGGLE_MORE_INFO_MODAL',
	TOGGLE_REMAN_PARTS_MODAL: 'TOGGLE_REMAN_PARTS_MODAL',
	TOGGLE_REMAN_PARTS_REQUEST_MODAL: 'TOGGLE_REMAN_PARTS_REQUEST_MODAL',
	TOGGLE_FAST_AVAILABILITY_MODAL: 'TOGGLE_FAST_AVAILABILITY_MODAL',
	TOGGLE_COLOR_SELECTION_MODAL: 'TOGGLE_COLOR_SELECTION_MODAL',
	TOGGLE_VEHICLE_MORE_INFO_MODAL: 'TOGGLE_VEHICLE_MORE_INFO_MODAL',
	TOGGLE_VEHICLE_EDIT_MODAL: 'TOGGLE_VEHICLE_EDIT_MODAL',
	TOGGLE_MEGA_MENU: 'TOGGLE_MEGA_MENU',
	TOGGLE_COLOR_CODE_INFO_MODAL: 'TOGGLE_COLOR_CODE_INFO_MODAL',
	TOGGLE_ALTERNATE_PART_MODAL: 'TOGGLE_ALTERNATE_PART_MODAL',
	TOGGLE_REPLACEMENT_CHAIN_MODAL: 'TOGGLE_REPLACEMENT_CHAIN_MODAL',
	TOGGLE_COMPANY_DATA_EDIT_MODAL: 'TOGGLE_COMPANY_DATA_EDIT_MODAL',
	TOGGLE_REQUEST_CREATED_MODAL: 'TOGGLE_REQUEST_CREATED_MODAL',
	TOGGLE_PART_PACKAGE_MODAL: 'TOGGLE_PART_PACKAGE_MODAL',
	TOGGLE_FREE_TEXT_SEARCH_MODAL: 'TOGGLE_FREE_TEXT_SEARCH_MODAL',
	TOGGLE_MODEL_RANGE_SELECTION_MODAL: 'TOGGLE_MODEL_RANGE_SELECTION_MODAL',
	TOGGLE_FOOTNOTE_MODAL: 'TOGGLE_FOOTNOTE_MODAL',
	TOGGLE_BANNER_NOTIFICATION_MODAL: 'TOGGLE_BANNER_NOTIFICATION_MODAL',
	TOGGLE_NEW_FEATURES_MODAL: 'TOGGLE_NEW_FEATURES_MODAL',
	TOGGLE_ADDITIONAL_INFO_NOTIFICATION: 'TOGGLE_ADDITIONAL_INFO_NOTIFICATION',
	TOGGLE_BULK_ORDER_MODAL: 'TOGGLE_BULK_ORDER_MODAL',
	TOGGLE_RETAILER_OPENING_HOURS_MODAL: 'TOGGLE_RETAILER_OPENING_HOURS_MODAL',
	TOGGLE_LIMITED_ADMIN_ACCESS_MODAL: 'TOGGLE_LIMITED_ADMIN_ACCESS_MODAL',
	TOGGLE_CONTENT_REPORTING_MODAL: 'TOGGLE_CONTENT_REPORTING_MODAL',
	TOGGLE_SPECIAL_MODIFICATION_VEHICLE_MODAL: 'TOGGLE_SPECIAL_MODIFICATION_VEHICLE_MODAL',
	TOGGLE_AGGREGATE_NUMBER_SELECTION_MODAL: 'TOGGLE_AGGREGATE_NUMBER_SELECTION_MODAL',
	TOGGLE_BASKET_BULK_DELETE_CONFIRMATION_MODAL: 'TOGGLE_BASKET_BULK_DELETE_CONFIRMATION_MODAL',
	TOGGLE_ADD_OLD_VEHICLE_CONFIRM_MODAL: 'TOGGLE_ADD_OLD_VEHICLE_CONFIRM_MODAL',
	TOGGLE_MODEL_IDENTIFICATION_MODAL: 'TOGGLE_MODEL_IDENTIFICATION_MODAL',
	TOGGLE_CATALOGUE_FILTER_MODAL: 'TOGGLE_CATALOGUE_FILTER_MODAL',
	TRIGGER_CATALOG_OPTION_SELECTION_MODAL: 'TRIGGER_CATALOG_OPTION_SELECTION_MODAL',
	TOGGLE_RETAILER_DELETE_CONFIRMATION_MODAL: 'TOGGLE_RETAILER_DELETE_CONFIRMATION_MODAL',
	TOGGLE_ADD_BASKET_DIRECT_MODAL: 'TOGGLE_ADD_BASKET_DIRECT_MODAL',
	TOGGLE_ERROR_MODAL: 'TOGGLE_ERROR_MODAL',
	TOGGLE_LUCKY_DRAW_MODAL: 'TOGGLE_LUCKY_DRAW_MODAL',
	TOGGLE_LUCKY_DRAW_PARTICIPATION_SUCCESS_MODAL: 'TOGGLE_LUCKY_DRAW_PARTICIPATION_SUCCESS_MODAL',
	TOGGLE_NO_ACTIVE_LUCKY_DRAW_MODAL: 'TOGGLE_NO_ACTIVE_LUCKY_DRAW_MODAL',
	TOGGLE_UNLOCK_VIA_PPV_MODAL: 'TOGGLE_UNLOCK_VIA_PPV_MODAL',
	TOGGLE_PUBLIC_SUPPORT_MODAL: 'TOGGLE_PUBLIC_SUPPORT_MODAL',
	TOGGLE_LOGIN_ERROR_MODAL: 'TOGGLE_LOGIN_ERROR_MODAL',
};
