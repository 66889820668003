import { SystemContractData } from '@Apis/shop';
import { TFunction } from 'next-i18next';
import { Markets } from '@Constants/market';

export const DESCRIPTION_MAX_CHARACTERS = 500;
export const MAX_FILE_COUNT = 10;
export const RTS_REQUEST_TIMEOUT = 300;
export const fileUploadMaxTotal = 104857600;

export enum SupportProductIdentifier {
	XPTEU = 'XPTEU',
	XD_LITE = 'XD_LITE',
}

export enum DocumentReadyState {
	COMPLETE = 'complete',
	LOADING = 'loading',
	INTERACTIVE = 'interactive',
}

export enum ScreenType {
	NOT_SET = 'NOT_SET',
	DEFAULT_FORM = 'DEFAULT_FORM',
	FORM_WITH_SYSTEM_ID = 'FORM_WITH_SYSTEM_ID',
	DEALER_LOCATOR = 'DEALER_LOCATOR',
	PUBLIC_FORM = 'PUBLIC_FORM',
	SELECT_TOPIC = 'SELECT',
	ERROR = 'ERROR',
	CONTACT_LOCAL_REPRESENTATIVE = 'CONTACT_LOCAL_REPRESENTATIVE',
	RICH_TEXT_CONTENT = 'RICH_TEXT_CONTENT',
}

export const SCROLL_DISABLED_STATES = [
	ScreenType.NOT_SET,
	ScreenType.SELECT_TOPIC,
	ScreenType.CONTACT_LOCAL_REPRESENTATIVE,
];

export const PRODUCTS_WITH_HARDWARE = [SupportProductIdentifier.XPTEU, SupportProductIdentifier.XD_LITE];

export const SCREENS_WITHOUT_PUBLIC_REDIRECTION = [
	ScreenType.DEALER_LOCATOR,
	ScreenType.CONTACT_LOCAL_REPRESENTATIVE,
	ScreenType.RICH_TEXT_CONTENT,
];

export const MARKETS_WITH_DIFFERENT_RETAILER_ALLOW_KEY: string[] = [
	Markets.GERMANY,
	Markets.UNITED_KINGDOM,
	Markets.NETHERLANDS,
	Markets.PORTUGAL,
	Markets.SPAIN,
];

export const VEHICLE_DIVISION_OPTIONS = (t: TFunction) => [
	{ label: t('support:passenger-car-van'), value: 0 },
	{ label: t('support:passenger-car'), value: 1 },
	{ label: t('support:passenger-van'), value: 2 },
];

export enum RTSSupportTeam {
	DIAGNOSIS = 'DIAGNOSIS',
	B2BC = 'B2BC',
	UMAS = 'UMAS',
	WEBPARTS = 'WEBPARTS',
	XCA = 'XCA',
	DSB = 'DSB',
	MARKET_ORGANIZATION = 'MARKET_ORGANIZATION',
	ASP = 'ASP',
}

export interface SupportTopic {
	supportTeam: string;
	topic: string;
}

export interface SupportProduct {
	product: string;
	ticketTopicList: SupportTopic[];
}

export interface SupportProductWrapper {
	label: string;
	product: SupportProduct[];
	rtsVersion: string;
}

export interface SupportTopicWrapper {
	label: string;
	topic: SupportTopic[];
}

export enum ControlUnitExhanged {
	YES = '0',
	NO = '1',
}

export interface SupportQuery {
	productSelection: SupportProductWrapper;
	topicSelection: SupportTopicWrapper;
	vehicleDivisionSelection: string;
	rtsVersion: RTSVersion;
	systems: SystemContractData[];
	systemsError: boolean;
	customContent: string;
	dealerId: string;
}

export enum RTSVersion {
	RTS_12 = '12',
	RTS_19 = '19',
	RTS_36 = '36',
}

export interface FileWithPath extends File {
	readonly path?: string;
}

export interface SupporData {
	query: SupportQuery;
	path: string;
	asPath: string;
}

export enum SupportFormDataKey {
	ATTACHMENT = 'Attachments',
	DESCRIPTION = 'Description',
	LANG = 'Languages',
	PRODUCT = 'Product',
	KEYWORD = 'Keyword',
	VEHICLE_DIV = 'VehicleDivision',
	RTS_TOPIC = 'RtsApiVersion',
	USE_CASE = 'UseCase',
	CONTROL_UNIT_EXCHANGED = 'ControlUnitExchanged',
	SYSTEM_ID = 'SystemId',
	PHONE = 'PhoneNumber',
	NAME = 'Name',
	FIRSTNAME = 'FirstName',
	LAST_NAME = 'LastName',
	EMAIL = 'Email',
	COMPANY = 'Company',
	CLIENT_NUMBER = 'ClientNumber',
	STREET = 'Street',
	STREET_NUMBER = 'StreetNumber',
	POSTAL_CODE = 'PostalCode',
	TOWN = 'Town',
	QUERY = 'Query',
	QUERY_TYPE = 'QueryType',
	QUERY_DETAIL = 'QueryDetail',
	APPLICATION = 'Application',
	OPERATION_STEP = 'OperationStep',
	SYMPTOM = 'Symptom',
	DEALER_ID = 'DealerId',
	COMPANY_NAME = 'CompanyName',
	ISSUE_TYPE = 'Type',
	USER_ID = 'UserId',
	ORDER_NUMBER = 'OrderNumber',
}

export enum OperationStep {
	OPERATE_XENTRY_DIAGNOSIS_PAD = 'OPERATE_XENTRY_DIAGNOSIS_PAD',
	GENERAL = 'GENERAL',
	CU_ADJUSTMENTS = 'CU_ADJUSTMENTS',
}

export enum IssueType {
	LOGIN,
	REGISTRATION,
}

export const OperationStepValueMapping = {
	[OperationStep.OPERATE_XENTRY_DIAGNOSIS_PAD]: 11,
	[OperationStep.CU_ADJUSTMENTS]: 27,
	[OperationStep.GENERAL]: 2,
};
