import { createRef, FC, ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { isEmpty } from 'lodash';
import Stepper from '@isp/stepper';
import Loading, { LoadingSizes } from '@isp/loading';
import Price from '@isp/price';
import Icon, { IconSize, IconType, IconVariant } from '@isp/icon';
import { Equipment, updateEquipmentBasket } from '@Apis/shop';
import AvailabilityInfo from '@Partials/Equipment/Catalogue/EquipmentItem/AvailabilityInfo';
import LoginTooltip from '@Partials/Equipment/Catalogue/EquipmentItem/LoginTooltip';
import XentryShopAccessTooltip from '@Shared/tooltips/xentry-shop-access-tooltip';
import { AppDispatch, RootState } from '@Redux';
import { UPDATE_BASKET } from '@Reducers/shop/actions';
import { OPEN_REGISTRATION } from '@Reducers/modals/actions';
import { UpdateEquipmentBasketRequestBody } from '@Reducers/shop/models';
import {
	PushDataToTagManagerForAddToCart,
	PushDataToTagManagerForButtons,
} from '@Helpers/google-analytics/pushDataToTagManager';
import { useTranslation } from 'react-i18next';
import ISPTooltip, { TooltipThemes, TooltipPositions } from '@isp/tooltip';
import { extractCurrencySymbol, getCurrencyFromSymbol } from '@Helpers/shop/price';
import { getItemNumericPrice } from '@Helpers/google-analytics/getItemData';
import { BASE64_PREFIX } from '@Constants/common';
import {
	STEPPER_MIN_COUNT,
	STEPPER_MAX_COUNT,
	EquipmentAvailabilityStatus,
	EQUIPMENT_AVAILABILITY_MAPPING,
} from '@Constants/product/equipment';
import { getComponentId, Pages, Components, Buttons } from '@Constants/google-analytics';
import { OrderType } from '@Constants/orders';

interface EquipmentItemProps {
	id: number;
	equipment: Equipment;
	authorizationDisabled: boolean;
}

const EquipmentItem: FC<EquipmentItemProps> = ({ id, equipment, authorizationDisabled }) => {
	const { t } = useTranslation(['shop']);
	const [equipmentCount, setEquipmentCount] = useState(1);
	const tooltipRef = createRef<HTMLDivElement>();
	const dispatch: AppDispatch = useDispatch();
	const addToBasketError = useSelector<RootState, boolean>(state => state.shop.addToBasketError);
	const addToBasketLoading = useSelector<RootState, boolean>(state => state.shop.addToBasketLoading);
	const updatedProductId = useSelector<RootState, string>(state => state.shop.updatedProductId);
	const isUserLoggedIn = useSelector<RootState, boolean>(state => state.user.isUserLoggedIn);
	const showBasketNotification = useSelector<RootState, boolean>(state => state.shop.showBasketNotification);
	const { LOW_AVAILABILITY, NOT_AVAILABLE } = EquipmentAvailabilityStatus;
	const { image, equipmentName, itemNumber, availability, price, rawPrice, shortDescription } = equipment;
	const availabilityMapping = EQUIPMENT_AVAILABILITY_MAPPING[availability];
	const { color, description } = availabilityMapping;
	const showInfoIcon = [LOW_AVAILABILITY, NOT_AVAILABLE].includes(availability);
	const isAddToBasketAvailable = isUserLoggedIn && availability !== NOT_AVAILABLE && !authorizationDisabled;
	const isAddToBasketDisabled = !isAddToBasketAvailable || addToBasketLoading || showBasketNotification;
	const register = () => dispatch(OPEN_REGISTRATION());

	const handleTooltip = (component: ReactElement) => {
		if (!isUserLoggedIn) {
			return <LoginTooltip register={register}>{component}</LoginTooltip>;
		}
		if (authorizationDisabled) {
			return <XentryShopAccessTooltip>{component}</XentryShopAccessTooltip>;
		}
		return component;
	};

	const handleAddToBasketEvent = () => {
		const numericPrice = getItemNumericPrice(rawPrice);
		PushDataToTagManagerForAddToCart({
			currency: getCurrencyFromSymbol(extractCurrencySymbol(price)),
			value: equipmentCount * numericPrice,
			items: [
				{
					item_id: itemNumber,
					item_name: equipmentName,
					price: numericPrice,
					quantity: equipmentCount,
				},
			],
		});
	};

	const handleAddToBasket = async e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		const requestData: UpdateEquipmentBasketRequestBody = {
			productId: itemNumber,
			quantity: equipmentCount,
		};
		const isItemAdded = await dispatch(
			UPDATE_BASKET({
				data: requestData,
				addedProductName: equipmentName,
				showNotification: true,
				quantity: equipmentCount,
				cartType: OrderType.EQUIPMENT,
				updateBasketFunction: updateEquipmentBasket,
				useTimeZone: true,
			})
		);

		handleAddToBasketEvent();
		if (isItemAdded) setEquipmentCount(1);
	};

	return (
		<div className="b-b-1 bc-asphalt">
			<div className="equipment-item train__center">
				<div
					className={classNames(
						'equipment-item__image h-100-p b-r-1 bc-asphalt',
						{ 'bg-white': image },
						{ 'bg-white-smoke': !image }
					)}
				>
					{image && <img alt={equipmentName} src={`${BASE64_PREFIX}${image}`} />}
					{!image && <Icon type={IconType.CAMERA} variant={IconVariant.BROWNGREY} />}
				</div>
				<div data-public={!isUserLoggedIn} className="DaimlerCS-Demi fs-18 color-black-two equipment-item__name">
					<p
						data-tip=""
						ref={tooltipRef}
						data-effect="solid"
						data-for="short-description-tooltip"
						onMouseEnter={() => ReactTooltip.show(tooltipRef.current)}
						onMouseLeave={() => ReactTooltip.hide(tooltipRef.current)}
						className="equipment-item__name--tooltip"
					>
						{equipmentName}
					</p>
				</div>
				{!isEmpty(shortDescription) && (
					<ISPTooltip
						theme={TooltipThemes.DARK}
						place={TooltipPositions.RIGHT}
						id="short-description-tooltip"
						getContent={() => <p>{shortDescription}</p>}
					/>
				)}
				<div className="color-boulder">{itemNumber}</div>
				<div className={`DaimlerCS-Bold disp-flex train__center ${color}`}>
					<AvailabilityInfo
						id={id}
						description={description}
						showInfoIcon={showInfoIcon}
						availabilityValue={availability}
					/>
				</div>
				{handleTooltip(
					<Stepper
						value={equipmentCount}
						minCount={STEPPER_MIN_COUNT}
						maxCount={STEPPER_MAX_COUNT}
						onChange={setEquipmentCount}
						disabled={!isAddToBasketAvailable}
					/>
				)}
				<Price amount={price} />
				{updatedProductId === itemNumber && addToBasketLoading && <Loading size={LoadingSizes.MICRO} />}
				{!addToBasketLoading &&
					handleTooltip(
						<Icon
							colorOnHover
							size={IconSize.SMALL}
							type={IconType.BASKET}
							variant={IconVariant.BLUE}
							onClick={e => handleAddToBasket(e)}
							id={getComponentId([Pages.EQUIPMENT, Components.EQUIPMENT_ITEM, Buttons.ADD_TO_BASKET])}
							clickable={!isAddToBasketDisabled}
							disabled={isAddToBasketDisabled}
						/>
					)}
			</div>
			{addToBasketError && updatedProductId === itemNumber && (
				<div className="equipment-catalogue__error">
					<div className="equipment-item__image h-100-p b-r-1 bc-asphalt" />
					<div className="error-message m-r-2">
						<Icon type={IconType.ERROR2} variant={IconVariant.BLOODRED} />
						<p>{t('shop:something-went-wrong-try-later')}</p>
					</div>
				</div>
			)}
		</div>
	);
};

export default EquipmentItem;
