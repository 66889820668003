import { useEffect, useRef, useState } from 'react';
import NextI18Next from '@Lib/i18n';
import Icon, { IconSize, IconType } from '@isp/icon';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import { geti18nLanguage } from '@Helpers/language/geti18nLanguage';
import { VideoPlayer, PosterBackgrounds, VideoCrossOrigins, VideoSubtitle } from '@isp/video-player';
import { checkEndpointAvailability } from '@Network/index';
import { Buttons, Components, getComponentId, Pages } from '@Constants/google-analytics';
import './index.scss';

const VideoModule = ({ video, videoSubtext, thumbnailImage, videoSubtitles }) => {
	const language = geti18nLanguage(NextI18Next.i18n.language);
	const [filteredSubtitles, setFilteredSubtitles] = useState<VideoSubtitle[]>([]);
	const handleThumbnailClick = useRef(null);

	useEffect(() => {
		filterSubtitles();
	}, []);

	const filterSubtitles = async () => {
		if (videoSubtitles) {
			const subtitlesTemp = [];

			videoSubtitles.forEach(async videoSubtitle => {
				checkEndpointAvailability(videoSubtitle.fileUrl).then(() => {
					subtitlesTemp.push(videoSubtitle);
				});
			});
			setFilteredSubtitles(subtitlesTemp);
		}
	};

	return (
		<div className="video-module br-4">
			<VideoPlayer
				key={language}
				src={video?.url}
				id={getComponentId([Pages.HOME, Components.VIDEO])}
				onPosterClick={e => PushDataToTagManagerForButtons((e.target as HTMLElement).id, window.location.href)}
				posterSrc={thumbnailImage?.url}
				posterBackground={PosterBackgrounds.CROSS_GRADIENT}
				handleThumbnailClick={handleThumbnailClick}
				tracks={filteredSubtitles}
				crossOrigin={VideoCrossOrigins.ANONYMOUS}
				playIcon={IconType.VIDEO_PLAY}
				posterChildren={
					<div
						className="video-module__play p-4 disp-flex train__center train__justify-center"
						id={getComponentId([Pages.HOME, Buttons.VIDEO])}
					>
						<Icon
							className="disp-flex train__center train__justify-center"
							type={IconType.VIDEO_PLAY}
							size={IconSize.LARGE}
						/>
					</div>
				}
			/>
			<p className="color-brownish-grey m-t-2 video-module__subtext">{videoSubtext}</p>
		</div>
	);
};

export default VideoModule;
