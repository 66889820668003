import { useRef, useState } from 'react';
import Tab from '@isp/tab';
import ModuleHeader from '@Partials/NewHome/Shared/ModuleHeader';
import CMSContent, { CMSContentThemes } from '@Shared/cms-content/CMSContent';
import { BenefitModuleData } from '@Umbraco/home';
import { useAnimation } from '@Helpers/hooks/useAnimation';
import Tabs, { TabsMobileDisplayOption, TabsThemes } from '@isp/tabs';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import { useComponentDidMount } from '@Helpers/hooks/useComponentDidMount';
import { Buttons, Components, Pages, getComponentId } from '@Constants/google-analytics';
import './index.scss';

const BenefitsModule = ({ descriptiveText, headline, tabs }: BenefitModuleData) => {
	const [activeTabId, setActiveTabId] = useState(tabs[0]?.id);
	const boxWrapper = useRef<HTMLDivElement>();
	useAnimation(boxWrapper);

	useComponentDidMount(() => {
		const imagesToBePreloaded = tabs.map(tab => tab?.illustration);
		imagesToBePreloaded.forEach(image => {
			new Image().src = image?.url;
		});
	});

	return (
		<div className="home__modules p-h-10 m-auto disp-grid grid--item-gap-3 train__justify-center" ref={boxWrapper}>
			<div className="home__modules__benefits br-4 p-t-16 p-b-16 p-h-20">
				<ModuleHeader title={headline} note={descriptiveText} />
				<span className="m-t-5">
					<Tabs
						theme={TabsThemes.LIGHT}
						activeTabId={activeTabId}
						mobileDisplay={TabsMobileDisplayOption.ACCORDION}
						accordionViewClassName="br-4 bg-white-smoke m-t-2 p-5 w-100-p"
						accordionClassName="w-100-p"
					>
						{tabs.map((tab, index) => (
							<Tab
								theme={TabsThemes.LIGHT}
								tabId={tab.id}
								title={tab.tabTitle}
								onTabClick={e => {
									PushDataToTagManagerForButtons((e.target as HTMLInputElement).id, window.location.href);
									setActiveTabId(tab.id === activeTabId ? null : tab.id);
								}}
								preventOnClick={tab.id === activeTabId}
								key={tab.id}
								linkClassName={`DaimlerCS-Bold fs-16 color-black-two ${
									activeTabId === tab.id && 'pointer-events-none'
								}`}
								id={getComponentId([Pages.HOME, Components.BENEFITS_MODULE, Buttons.TAB_CLICK, index.toString()])}
							>
								<div className="home__modules__benefits__content disp-grid train__center">
									<CMSContent
										theme={CMSContentThemes.DARK}
										content={tab.description}
										contentClassName="p-0 fs-16 color-charcoal-grey"
									/>
									<img
										className="w-100-p"
										src={tab?.illustration?.url}
										alt={tab?.illustration?.altText || 'B2B Connect Benefit'}
									/>
								</div>
							</Tab>
						))}
					</Tabs>
				</span>
			</div>
		</div>
	);
};

export default BenefitsModule;
