import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ISPButton from '@isp/button';
import Price from '@isp/price';
import { LinkVariant } from '@isp/link';
import Loading, { LoadingSizes, LoadingThemes } from '@isp/loading';
import ISPIcon, { IconVariant, IconSize, IconType } from '@isp/icon';
import InternalLink from '@Shared/links/InternalLink';
import ISPError from '@Shared/error';
import { getRoutesAs } from '@Routes';
import { RmiLicenseType } from '@Reducers/user/models';
import { getRmiLicenses } from '@Apis/customer';
import { getShopProduct, ShopProductSubscriptionPlan } from '@Apis/shop';
import { GoToExternalURL } from '@Helpers/ExternalURLs';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import { RootState } from '@Redux';
import { ShopProductKeys } from '@Constants/shop';
import { Buttons, Components, getComponentId, Pages } from '@Constants/google-analytics';
import '@Partials/Equipment/Header/index.scss';

interface ProductCardProps {
	icon: IconType;
	productKey: ShopProductKeys;
	title: string;
	description: string;
	route: string;
	market: string;
	productCode: string;
}

const ProductCard: FC<ProductCardProps> = ({ icon, productKey, title, description, route, market, productCode }) => {
	const { t } = useTranslation(['common', 'shop', 'rmi']);
	const [subscriptionPlan, setSubscriptionPlan] = useState<ShopProductSubscriptionPlan>(null);
	const [subscriptionPlansLoading, setSubscriptionPlansLoading] = useState<boolean>(true);
	const [subscriptionPlansError, setSubscriptionsError] = useState<boolean>(false);
	const [licenseLoading, setLicenseLoading] = useState<boolean>(false);
	const [licenseError, setLicenseError] = useState<boolean>(false);
	const [license, setLicense] = useState<RmiLicenseType>(null);
	const isUserLoggedIn = useSelector<RootState, boolean>(state => state.user.isUserLoggedIn);

	const hasActiveLicense = license ? license[0]?.hasActiveLicense : false;

	const showError = !licenseLoading && !subscriptionPlansLoading && (licenseError || subscriptionPlansError);
	const showLoading = !licenseError && !subscriptionPlansError && (licenseLoading || subscriptionPlansLoading);
	const showContent = !showError && !showLoading && subscriptionPlan;

	const getLicense = () => {
		setLicense(null);
		setLicenseError(false);
		setLicenseLoading(true);

		getRmiLicenses(productKey)
			.then(licenseData => setLicense(licenseData?.data?.data))
			.catch(() => setLicenseError(true))
			.finally(() => setLicenseLoading(false));
	};

	const getSubscriptionPlans = () => {
		setSubscriptionPlan(null);
		setSubscriptionPlansLoading(true);
		setSubscriptionsError(false);

		getShopProduct(productCode)
			.then(response => {
				setSubscriptionPlan(response);
			})
			.catch(() => setSubscriptionsError(true))
			.finally(() => setSubscriptionPlansLoading(false));
	};

	useEffect(() => {
		if (isUserLoggedIn) getLicense();
		getSubscriptionPlans();
	}, []);

	return (
		<div className="product-card train">
			<div className="w-33-p bg-white-two disp-flex">
				<ISPIcon className="m-auto" variant={IconVariant.BLUE} size={IconSize.HUGE} type={icon} />
			</div>
			<div className="w-67-p p-l-4 p-t-4 p-b-10">
				<InternalLink
					href={route}
					as={getRoutesAs(route, { market })}
					className="product-card__title DaimlerCS-Demi fs-18"
					variant={LinkVariant.SECONDARY}
				>
					{title}
				</InternalLink>
				<p className="product-card__description p-t-2 fs-16">{description}</p>
				{showLoading && (
					<div className="train__flex train__center p-t-4">
						<Loading size={LoadingSizes.TINY} theme={LoadingThemes.LIGHT} />
						<p className="p-l-2">{t('common:checking-status')}</p>
					</div>
				)}
				{showError && (
					<div className="disp-flex m-t-2">
						<ISPError errorMessage="rmi:license-status-query-error" />
					</div>
				)}
				{showContent && (
					<>
						{!isUserLoggedIn && <ProductPrice subscriptionPlan={subscriptionPlan} translate={t} />}
						{isUserLoggedIn && (
							<LoggedInContent
								translate={t}
								productKey={productKey}
								hasActiveLicense={hasActiveLicense}
								subscriptionPlan={subscriptionPlan}
							/>
						)}
					</>
				)}
			</div>
		</div>
	);
};

const LoggedInContent = ({ hasActiveLicense, translate, subscriptionPlan, productKey }) => {
	const onClick = e => {
		PushDataToTagManagerForButtons(e.target.id);
		GoToExternalURL(`RMI_${productKey}`, true);
	};

	return (
		<>
			{!hasActiveLicense && <ProductPrice subscriptionPlan={subscriptionPlan} translate={translate} />}
			{hasActiveLicense && (
				<div className="p-t-4">
					<ISPButton
						className="p-h-8"
						outline
						onClick={e => onClick(e)}
						id={getComponentId([Pages.EQUIPMENT, Components.ACCESS, productKey, Buttons.GO_TO_URL], true, true)}
					>
						{translate('rmi:access')}
					</ISPButton>
				</div>
			)}
		</>
	);
};

const ProductPrice = ({ subscriptionPlan, translate }) => {
	const { price, durationType } = subscriptionPlan;
	const durationLabel = durationType ? translate(`shop:${durationType.toLocaleLowerCase()}-label`) : '';

	return (
		<div className="p-t-5">
			<div className="DaimlerCS-Demi fs-18 disp-flex">
				<Price amount={price} />
				<span className="DaimlerCS-Regular fs-14 m-l-1">
					{translate('common:per-label')} {durationLabel}
				</span>
			</div>
		</div>
	);
};

export default ProductCard;
