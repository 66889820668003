import cN from 'classnames';
import { useDispatch } from 'react-redux';
import { useTranslation } from '@Lib/i18n';
import { OPEN_REGISTRATION } from '@Reducers/modals/actions';
import Button from '@isp/button';
import { PushDataToTagManagerForButtons, PushEventToDataLayer } from '@Helpers/google-analytics/pushDataToTagManager';
import { EventActions, EventCategories } from '@Constants/google-analytics';
import { Events } from '@Constants/common';

const RegisterButton = ({ className = '', registrationRef = null, id }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation(['common']);

	const onRegisterClick = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		PushEventToDataLayer(Events.CLICK, {
			category: EventCategories.FEATURE,
			action: EventActions.REGISTRATION,
			label: EventActions.START,
		});
		dispatch(OPEN_REGISTRATION());
	};

	return (
		<Button refProp={registrationRef} onClick={onRegisterClick} className={cN('p-h-4 fs-16', className)} id={id}>
			{t('common:get-started')}
		</Button>
	);
};

export default RegisterButton;
