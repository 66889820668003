import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import Table, { TableHeaderItem, TableDataItem } from '@isp/table';
import Icon, { IconType, IconVariant } from '@isp/icon';
import Price, { PriceThemes } from '@isp/price';
import { PartsOrderItem } from '@Apis/orders';
import XWISDocumentFootnote from '@Shared/webparts/PartItemFootnotes/XWISDocumentFootnote';
import { DATE_FORMAT_MMM_D_YYYY } from '@Constants/common';
import { Buttons, Components, Pages, getComponentId } from '@Constants/google-analytics';

const OrderList = ({ orderDetails }) => {
	const { t } = useTranslation(['orders', 'vehicles', 'shop']);
	const headerDataClass = 'no-white-space fw-normal fs-16 p-t-2 b-b-1 bc-very-light-pink-3 color-brownish-grey';
	const { orderItems: parts, vin: orderVin } = orderDetails;
	const getOrderTableHeader = () => {
		const itemColumn: TableHeaderItem = {
			data: <div className={headerDataClass}>{t('orders:order-details-label-item')}</div>,
			textAlignment: 'left',
		};
		const discountGroupColumn: TableHeaderItem = {
			data: <div className={headerDataClass}>{t('shop:basket-discount-group')}</div>,
			textAlignment: 'left',
		};
		const priceColumn: TableHeaderItem = {
			data: <div className={`order-list__item-price ${headerDataClass}`}>{t('orders:order-details-label-price')}</div>,
			textAlignment: 'right',
			colSpan: 2,
		};
		const quantityColumn: TableHeaderItem = {
			data: <div className={headerDataClass}>{t('vehicles:quantity-title')}</div>,
			textAlignment: 'right',
		};
		const priceSumColumn: TableHeaderItem = {
			data: <div className={headerDataClass}>{t('orders:sum-net-title')}</div>,
			textAlignment: 'right',
		};

		return {
			data: [itemColumn, discountGroupColumn, priceColumn, quantityColumn, priceSumColumn],
		};
	};

	const getOrderRow = (item: PartsOrderItem) => {
		const { t } = useTranslation(['shop']);
		const { price, isRemanPart, isStarPart, name, partNumber, discountGroup, quantity } = item;
		const {
			itemListPrice,
			itemNetPrice,
			itemDiscount,
			itemDiscountPercent,
			specialOffer,
			netPriceSum,
			netPriceSumIncludingVat,
		} = price;
		const itemPartTypeAvailable = isRemanPart || isStarPart;
		const specialOfferAvailable = !isEmpty(specialOffer) && specialOffer.specialOfferDiscountAbsolute;

		const formatSpecialOfferDate = (dateString: string) => dayjs(dateString).format(DATE_FORMAT_MMM_D_YYYY);

		const itemColumn: TableDataItem = {
			data: (
				<div className="train train__center">
					<div>
						<div className="order-list__item-name DaimlerCS-Bold fs-16">{name}</div>
						<div className="fs-16">{partNumber}</div>
						{orderVin && (
							<XWISDocumentFootnote
								partNumber={partNumber}
								partName={name}
								vin={orderVin}
								id={getComponentId(
									[
										Pages.ORDERS,
										Components.ORDER_DETAILS,
										Components.ORDER_ITEM,
										Components.FOOTNOTE,
										Buttons.OPEN,
										'wis-document',
										partNumber,
									],
									true,
									true
								)}
							/>
						)}
					</div>
					<div className="disp-flex m-t-1">
						{itemPartTypeAvailable && (
							<Icon
								type={isRemanPart ? IconType.REMAN_PARTS_FOOTNOTE : IconType.START_PARTS_FOOTNOTE}
								variant={IconVariant.BLACK}
								className="m-r-2"
							/>
						)}
					</div>
				</div>
			),
			textAlignment: 'left',
			tableDataClassName: 'order-info__text-column p-v-2',
		};

		const discountGroupColumn: TableDataItem = {
			data: <div className="">{discountGroup}</div>,
			textAlignment: 'left',
			tableDataClassName: 'order-info__text-column p-v-2',
		};
		const priceColumn: TableDataItem = {
			data: (
				<div className="order-list__item-price">
					<div className="disp-flex train__space-between">
						<div className="no-white-space color-brownish-grey m-r-2">{t('shop:list-price')}</div>
						<Price
							amount={itemListPrice}
							theme={PriceThemes.SECONDARY}
							className="disp-flex train__center train__flex-end"
						/>
					</div>
					<div className="disp-flex train__space-between">
						<div className="no-white-space color-brownish-grey m-r-2">{`${t(
							'shop:discount'
						)} (${itemDiscountPercent} %)`}</div>
						<Price
							amount={itemDiscount}
							theme={PriceThemes.SECONDARY}
							className="disp-flex train__center train__flex-end"
						/>
					</div>
					{specialOfferAvailable && (
						<>
							<div className="disp-flex train__space-between">
								<div className="no-white-space color-brownish-grey m-r-2">
									{t('shop:promotion-discount-with-percentage', {
										PERCENTAGE: specialOffer.specialOfferDiscountPercent,
									})}
								</div>
								<Price
									amount={specialOffer.specialOfferDiscountAbsolute}
									theme={PriceThemes.SECONDARY}
									className="disp-flex train__center train__flex-end"
								/>
							</div>
							<div className="disp-flex train__space-between">
								<div className="no-white-space color-brownish-grey m-r-2">
									{`${formatSpecialOfferDate(specialOffer.specialOfferFrom)} - ${formatSpecialOfferDate(
										specialOffer.specialOfferTo
									)}`}
								</div>
								<div className="disp-flex train__center train__flex-end" />
							</div>
						</>
					)}
					<div className="disp-flex train__space-between">
						<div className="no-white-space color-brownish-grey m-r-2">{t('shop:net-price')}</div>
						<Price
							amount={itemNetPrice}
							theme={PriceThemes.SECONDARY}
							className="disp-flex train__center train__flex-end"
						/>
					</div>
				</div>
			),
			textAlignment: 'left',
			tableDataClassName: 'order-info__text-column p-v-2',
			colSpan: 2,
		};

		const quantityColumn: TableDataItem = {
			data: <div>{quantity}</div>,
			textAlignment: 'right',
			tableDataClassName: 'order-info__text-column p-v-2',
		};

		const priceSumColumn: TableDataItem = {
			data: (
				<div className="order-info__item-sum">
					<Price amount={netPriceSum} theme={PriceThemes.SECONDARY} className="train train__flex-end" />
					<div className="disp-flex train__flex-end">
						<div className="color-brownish-grey m-r-2">{t('orders:including-vat')}</div>
						<Price amount={netPriceSumIncludingVat} theme={PriceThemes.TERTIARY} />
					</div>
				</div>
			),
			textAlignment: 'right',
			tableDataClassName: 'order-info__text-column p-v-2',
		};

		return {
			data: [itemColumn, discountGroupColumn, priceColumn, quantityColumn, priceSumColumn],
			tableRowClassName: 'b-b-1 bc-very-light-pink-3',
		};
	};

	return (
		<Table
			tableClassName="w-100-p"
			rows={parts.map(part => getOrderRow(part))}
			header={getOrderTableHeader()}
			resizableCellMinWidth={120}
			resizable
			resizeBorderClass=""
		/>
	);
};

export default OrderList;
