import { useRef, useState } from 'react';
import Image from 'next/image';
import { useAnimation } from '@Helpers/hooks/useAnimation';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Icon, { IconSize, IconType, IconVariant } from '@isp/icon';
import Chip, { ChipThemes } from '@isp/chip';
import CMSContent, { CMSContentThemes } from '@Shared/cms-content/CMSContent';
import { Buttons, Components, Pages, getComponentId } from '@Constants/google-analytics';
import DATA_QA from '@Constants/style/data-qa-selector.json';

const QuoteModule = ({ quoteList }) => {
	const boxWrapper = useRef<HTMLDivElement>();
	const sliderRef = useRef(null);
	useAnimation(boxWrapper);

	const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: <SliderArrow direction={IconType.ARROW_RIGHT} />,
		prevArrow: <SliderArrow direction={IconType.ARROW_LEFT} />,
		customPaging: () => (
			<div className="pager__item">
				<span />
			</div>
		),
		beforeChange: (_, newIndex) => {
			setCurrentSlideIndex(newIndex);
		},
	};

	return (
		<div ref={boxWrapper} className="home__modules p-h-10 m-auto">
			<div className="home__modules__quote">
				<Slider {...settings} className="p-h-8" ref={sliderRef}>
					{quoteList?.map((item, index) => (
						<QuoteItem key={index} {...item} />
					))}
				</Slider>
				<div className="home__modules__quote__item">
					<span className="home__modules__quote__item-mobile-content">
						<blockquote className="color-silver DaimlerCAC-Regular home__modules__quote__item-cms-content">
							<CMSContent
								theme={CMSContentThemes.DARK}
								content={quoteList[currentSlideIndex]?.quote}
								contentClassName="p-0"
							/>
							<cite className="DaimlerCS-Regular color-black-two">{quoteList[currentSlideIndex]?.subtext}</cite>
						</blockquote>
					</span>
				</div>
			</div>
		</div>
	);
};

const QuoteItem = ({ quote, ispImage, subtext, chipLabel }) => {
	const mediaAvailable = ispImage && ispImage.url;
	return (
		<div
			className="home__modules__quote__item disp-grid train__center train__justify-center"
			data-no-media={!mediaAvailable}
		>
			{mediaAvailable && (
				<span className="home__modules__quote__item-image pointer-events-none">
					<Image src={ispImage.url} alt={ispImage.altText} width={172} height={172} />
					{chipLabel && (
						<Chip value={chipLabel} theme={ChipThemes.SOLID_DARK} className="home__modules__quote__item-chip" />
					)}
				</span>
			)}
			<span className="home__modules__quote__item-content">
				<blockquote className="color-silver DaimlerCAC-Regular home__modules__quote__item-cms-content">
					<CMSContent theme={CMSContentThemes.DARK} content={quote} contentClassName="p-0" />
					<cite className="DaimlerCS-Regular color-black-two">{subtext}</cite>
				</blockquote>
			</span>
		</div>
	);
};

const SliderArrow = props => {
	const { className, onClick, direction } = props;
	const directionIdMapping = {
		[IconType.ARROW_RIGHT]: {
			id: getComponentId([Pages.HOME, Components.QUOTE_MODULE, Buttons.NEXT]),
			dataQA: DATA_QA.QUOTE_MODULE_NEXT_ARROW,
		},
		[IconType.ARROW_LEFT]: {
			id: getComponentId([Pages.HOME, Components.QUOTE_MODULE, Buttons.PREVIOUS]),
			dataQA: DATA_QA.QUOTE_MODULE_PREVIOUS_ARROW,
		},
	};

	return (
		<div
			id={directionIdMapping[direction]?.id}
			className={className}
			onClick={onClick}
			data-qa={directionIdMapping[direction]?.dataQA}
		>
			<Icon type={direction} variant={IconVariant.DARKGRAY} size={IconSize.LARGE} colorOnHover />
		</div>
	);
};

export default QuoteModule;
