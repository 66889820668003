import client from '@Umbraco/client';
import CONFIG from '@Umbraco/config.json';
import { Image, Video, toImage, toVideo } from '@Umbraco/models';
import { ImagePosition } from '@Umbraco/product-store';

export enum CommunicationEventSectionType {
	VIDEO = 'VIDEO',
	MAP = 'MAP',
	CONTEST = 'CONTEST',
	FEATURE = 'FEATURE',
}

export interface CommunicationEventVideoChapter {
	chapterStart: number;
	chapterEnd: number;
	description: string;
	title: string;
}

export interface VideoSubtitle {
	srcLang: string;
	label?: string;
	fileUrl: string;
	default?: boolean;
}

export interface CommunicationEventVideoSection {
	identifier: CommunicationEventSectionType;
	title?: string;
	description?: string;
	logo?: Image;
	thumbnailImage?: Image;
	video?: Video;
	videoChapters?: CommunicationEventVideoChapter[];
	videoSubtitles?: VideoSubtitle[];
}

export interface CommunicationEventFeatureSection {
	identifier: CommunicationEventSectionType;
	title?: string;
	description?: string;
	media?: Image;
	video?: Video;
	mediaOrientation?: ImagePosition;
}

export const toVideoSubtitles = items =>
	(items || []).map(item => ({
		srcLang: item.sourceLanguage,
		label: item.label,
		fileUrl: item.subtitleFile?._url,
		default: item.default,
	}));

export const toVideoSection = item => ({
	identifier: item?.identifier,
	title: item?.title,
	description: item?.description,
	logo: toImage(item?.logo),
	thumbnailImage: toImage(item?.thumbnailImage),
	video: toVideo(item?.video),
	videoChapters: item?.videoChapters,
	videoSubtitles: toVideoSubtitles(item?.videoSubtitles),
});

export const toFeatureSection = item => ({
	identifier: item?.identifier,
	title: item?.title,
	description: item?.description,
	media: toImage(item?.media),
	mediaOrientation: item?.mediaOrientation,
});

export const getCommunicationEventPage = async (market: string, lang: string) => {
	const id = CONFIG.COMMUNICATION_EVENT;
	return client(`/content/${id}`, market, lang);
};
