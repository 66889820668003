import dayjs from 'dayjs';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import isBetween from 'dayjs/plugin/isBetween';
import calendar from 'dayjs/plugin/calendar';
import { DateRange, IncidentManagamentNotificationItem, IncidentType } from '@Umbraco/notifications';
import { getOrCreateStore } from '@Lib/with-redux-store';
import {
	CMS_EMPTY_DATE_VALUE,
	NotificationsDateLanguage,
	LeitstandType,
	NotificationsTypes,
	NOTIFICATION_REFRESH_DURATIONS,
} from '@Constants/notifications';
import { DATE_FORMAT_DDMMYYYY, DATE_FORMAT_YMD_HHMMSS } from '@Constants/common';

dayjs.extend(calendar);
dayjs.extend(isBetween);

export const setMomentLanguage = (language, market): string => {
	const languageParam = language === 'zh' ? `${language}_${market.toLowerCase()}` : language;
	const notificationsDateLanguage = NotificationsDateLanguage[languageParam];
	dayjs.locale(notificationsDateLanguage);
	return notificationsDateLanguage;
};

export function getDateLabel(eventDate, language, market) {
	let localization = null;
	const notificationsDateLanguage = setMomentLanguage(language, market);
	localization = require(`dayjs/locale/${notificationsDateLanguage}.js`); // eslint-disable-line
	return dayjs(eventDate).calendar(localization);
}

export const filterCMSNotificationsByDate = (notifications: DateRange[]) => {
	const nowDate = dayjs().unix();

	return notifications.filter(item => {
		const isEndDateEmpty = item.endDate === CMS_EMPTY_DATE_VALUE;
		const isStartDateEmpty = item.startDate === CMS_EMPTY_DATE_VALUE;
		const dateNotProvided = isEndDateEmpty && isStartDateEmpty;
		const validStartDateProvided = isEndDateEmpty && dayjs(item.startDate).isBefore(dayjs());

		if (dateNotProvided || validStartDateProvided) return true;

		const start = dayjs(item.startDate).unix();
		const end = dayjs(item.endDate).unix();

		return dayjs(nowDate).isBetween(start, end);
	});
};

export const getNotificatonMessageWithAffectedProduct = (notification: IncidentManagamentNotificationItem) => {
	const { incidentType } = notification;
	if (incidentType === IncidentType.PLANNED_MAINTENANCE) return handleMaintenanceNotification(notification);
	const affectedProducts = (notification?.affectedProducts || []).join(', ');
	const message = notification?.message || '';
	return message && !isEmpty(affectedProducts) ? `${message}: ${affectedProducts}` : message;
};

const handleMaintenanceNotification = (notification: IncidentManagamentNotificationItem) => {
	const messageBody = [];
	if (notification?.message || '') messageBody.push(notification?.message || '');
	const maintenanceStart = notification?.maintenanceStart;
	const maintenanceEnd = notification?.maintenanceEnd;
	if (
		maintenanceStart &&
		maintenanceEnd &&
		dayjs(maintenanceStart).isAfter(dayjs.unix(0)) &&
		dayjs(maintenanceEnd).isAfter(dayjs.unix(0))
	) {
		const startDate = dayjs(maintenanceStart).local().format(DATE_FORMAT_YMD_HHMMSS);
		const endDate = dayjs(maintenanceEnd).local().format(DATE_FORMAT_YMD_HHMMSS);
		messageBody.push(`${[startDate, endDate].join(' - ')}`);
	}
	const affectedProducts = (notification?.affectedProducts || []).join(', ');
	if (affectedProducts) messageBody.push(affectedProducts);
	return messageBody.join(' · ');
};

export const getIncidentManagementNotificationType = (notification: IncidentManagamentNotificationItem) => {
	return notification?.incidentType || IncidentType.GENERAL_INFORMATION;
};

export const isNotificationVisibleInCurrentMarket = (notification: IncidentManagamentNotificationItem) => {
	const store = getOrCreateStore({});
	const { code } = store.getState().configuration.market;
	return notification?.allMarkets || false || (notification?.markets || []).includes(code);
};

export const getDate = item => {
	const date =
		item.exportDate ||
		item.cancelDate ||
		dayjs(item.date || item.dateLabel || item.time || item.updateDate || item.startDate, DATE_FORMAT_DDMMYYYY).unix();

	return Number.isNaN(date)
		? dayjs(item.date || item.dateLabel || item.time || item.updateDate || item.startDate).unix()
		: date;
};

export const scoreAvailability = (firstItem, secondItem) => {
	let score = 0;
	const DATE_DIFF_SCORE = 1;
	const TYPE_SCORES = {
		[LeitstandType.INCIDENT]: 550,
		[LeitstandType.MAINTENANCE]: 300,
		[LeitstandType.INFORMATION]: 50,
		[IncidentType.INCIDENT]: 500,
		[IncidentType.PLANNED_MAINTENANCE]: 250,
		[IncidentType.GENERAL_INFORMATION]: 0,
	};
	if (firstItem.type === NotificationsTypes.LEITSTAND) {
		score += TYPE_SCORES[firstItem.leitstandType];
	} else if (firstItem.type === NotificationsTypes.INCIDENT) {
		score += TYPE_SCORES[getIncidentManagementNotificationType(firstItem)];
	}

	if (dayjs(getDate(firstItem)).isSameOrAfter(dayjs(getDate(secondItem)))) score += DATE_DIFF_SCORE;

	return score;
};

export const getNotificationRefreshDuration = (previousDuration = NOTIFICATION_REFRESH_DURATIONS[0]) => {
	const index = findIndex(NOTIFICATION_REFRESH_DURATIONS, item => item === previousDuration);
	return NOTIFICATION_REFRESH_DURATIONS[NOTIFICATION_REFRESH_DURATIONS.length - 1 !== index ? index + 1 : index];
};
