import { Languages } from '@Constants/account';

export enum Markets {
	AUSTRALIA = 'au',
	CANADA = 'ca',
	CHINA = 'cn',
	DENMARK = 'dk',
	FRANCE = 'fr',
	GERMANY = 'de',
	HONG_KONG = 'hk',
	KOREAN = 'kr',
	NETHERLANDS = 'nl',
	PORTUGAL = 'pt',
	SOUTH_AFRICA = 'za',
	SPAIN = 'es',
	SWEDEN = 'se',
	SWITZERLAND = 'ch',
	TAIWAN = 'tw',
	TURKEY = 'tr',
	UNITED_KINGDOM = 'gb',
	UNITED_KINGDOM_UK = 'uk',
	GREECE = 'gr',
	JAPAN = 'jp',
	DUBAI = 'ae',
}

export const CHINESE_LANGUAGES = [Languages.ZH_TW, Languages.ZH];

export const WEBPARTS_REDIRECTION_DISABLED_MARKETS = [Markets.KOREAN];

export const VAN_INCLUDED_PC_MARKETS = [Markets.TAIWAN, Markets.KOREAN];
