import { actionTypes } from '@Reducers/configuration/actionTypes';
import { ConfigurationType } from './models';

export const initialConfigurationState: ConfigurationType = {
	language: '',
	applicationSettings: null,
	languageConfiguration: {
		defaultLanguage: '',
		availableLanguages: [],
	},
	sectionConfiguration: {
		newsletter: false,
		starParts: false,
		subscriptionPlanDescription: false,
	},
	featureConfiguration: null,
	productContactEmail: '',
	market: null,
	unavailablePartOrderTypes: [],
};

export const configurationReducer = (state = initialConfigurationState, action) => {
	if (action.type === actionTypes.CHANGE_LANGUAGE) {
		return {
			...state,
			language: action.language,
		};
	}
	return state;
};

export default configurationReducer;
