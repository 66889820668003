import { IconSize, IconType } from '@isp/icon';
import { InputThemes } from '@isp/input';

export const DEFAULT_PRODUCT_PER_ROW = 5;
export const DEFAULT_PRODUCT_PER_ROW_WITH_FILTER = 4;
export const DEFAULT_PRODUCT_ROW_COUNT = 3;
export const DEFAULT_PRODUCT_ROW_TO_LOAD = 2;
export const TRADE_CLUB_IMAGE_SIZE = 490;
export const SLIDER_PRODUCT_COUNT = 3;

export const MENU_HEIGHT = 60;
export const BOTTOM_OFFSET = 40;

export const PRODUCT_TITLE_LIMIT = 50;
export const VERTICAL_SCROLL_NUMBER = 500;

export const STAR_PARTS_PRODUCT_LINE_CLAMP = 2;
export const REMAN_CATEGORY_CARD_LINE_CLAMP = 5;

export const SEARCH_WIDGET_CHAR_COUNT = 10;
export const SEARCH_WIDGET_MAX_CHAR_COUNT = 25;

export const REMAN_PRICE_DIVISOR = 10;
export const ENGINE_PRODUCT_SLUG = 'engines';
export const REQUEST_MODAL_LOGIN_TO_PURCHASE_ACTION = 'LOGIN_TO_PURCHASE';

export enum MainMenuCategories {
	RMI = 'RMI',
	DIAGNOSIS = 'DIAGNOSIS',
	MOBILE_APPS = 'MOBILE_APPS',
	TOOLS = 'TOOLS',
	PARTS = 'PARTS',
	TECHNICAL_DATA = 'TECHNICAL_DATA',
	COMMUNICATION_EVENT = 'COMMUNICATION_EVENT',
	B2B_CONNECT_APP = 'B2B_CONNECT_APP',
}

export const SOFTWARE_CATEGORIES = [
	MainMenuCategories.RMI,
	MainMenuCategories.DIAGNOSIS,
	MainMenuCategories.MOBILE_APPS,
];

export enum ProductFilterConfiguration {
	PARTS = 'PRODUCT_FILTER_CONFIGURATION_PARTS',
	CHEMICAL_PRODUCTS = 'PRODUCT_FILTER_CONFIGURATION_CHEMICAL_PRODUCTS',
}

export enum SubscriptionTypes {
	HOUR = 'HOUR',
	DAY = 'DAY',
	WEEK = 'WEEK',
	MONTH = 'MONTH',
	YEAR = 'YEAR',
	FIXED = 'FIXED',
	CREDIT = 'CREDIT',
}

export enum ProductNames {
	XENTRY_PASS_THRU_EU = 'XENTRY Pass Thru EU',
	XENTRY_DIAGNOSIS_LITE = 'XENTRY Diagnosis Lite',
	PAY_PER_VIN = 'PAY PER VIN',
}

export enum ProductKeys {
	XENTRY_PASS_THRU_EU = 'XENTRY_PASS_THRU_EU',
	XENTRY_TIPS = 'XENTRY_TIPS',
	XENTRY_DIAGNOSIS_SYSTEM = 'XENTRY_DIAGNOSIS_SYSTEM',
	WIS = 'WIS', // XENTRY WIS
	ASRA = 'ASRA', // XOT
	XOT_TITLED = 'XOT',
	PARTS_INFORMATION = 'PARTS_INFORMATION',
	DIGITAL_SERVICE_BOOKLET = 'DIGITAL_SERVICE_BOOKLET',
	FREE_CONTENT = 'FREE_CONTENT',
	XENTRY_FLASH = 'XENTRY_FLASH',
	XENTRY_SCOPE = 'XENTRY_SCOPE',
	SHOP_PRODUCT_XENTY_SCOPE = 'SHOP_PRODUCT_XENTY_SCOPE',
	RETAIL_DATA_STORAGE = 'RETAIL_DATA_STORAGE',
	XENTRY_ACCESSORIES = 'XENTRY_ACCESSORIES',
	XRD = 'XRD',
	PTI = 'PTI',
	TECHNICAL_DATA = 'TECHNICAL_DATA',
	RMS = 'RMS',
	RDS = 'RDS',
	XENTRY_TIPS_APP = 'XENTRY_TIPS_APP',
	XSHOP_RMI = 'XSHOP_RMI',
	XDIAGNOSIS = 'XDIAGNOSIS',
	PARTS_APP = 'PARTS_APP',
	EQUIPMENT_PAGE = 'EQUIPMENT_PAGE',
	XENTRY_TIPS_MOBILE = 'XENTRY_TIPS_MOBILE',
	XD_LITE = 'XD_LITE',
	XD4IW = 'XD4IW',
	DWD = 'DWD',
	B2B_CONNECT_APP = 'B2B_CONNECT_APP',
	PARTS_GOAL = 'PARTS_GOAL',
	WAO = 'WAO',
	PAY_PER_VIN = 'PAY_PER_VIN',
}

export enum ProductIcons {
	XENTRY_PASS_THRU_EU = 'DIAGNOSIS',
	XENTRY_TIPS = 'ACCIDENT',
	XENTRY_DIAGNOSIS_SYSTEM = 'DIAGNOSIS',
	WIS = 'SERVICE_EXECUTION',
	ASRA = 'STOPWATCH',
	PARTS_INFORMATION = 'BOOKSCREW',
	DIGITAL_SERVICE_BOOKLET = 'DSB',
	RMS = 'WIFI',
	RDS = 'WIFI',
	XRD = 'XENTRY_REMOTE_DIAGNOSIS_APP',
	XENTRY_TIPS_MOBILE = 'XENTRY_REMOTE_DIAGNOSIS_APP',
}

export enum StoreKeys {
	PARTS = 'PARTS',
	CHEMICAL_PRODUCTS_AND_CAR_CARE = 'CHEMICAL_PRODUCTS_AND_CAR_CARE',
	ORIGINAL_ENGINE_OIL = 'ORIGINAL_ENGINE_OIL',
	REMAN_PARTS = 'REMAN_PARTS',
	TIRES = 'TIRES',
	TRADE_CLUB = 'TRADE_CLUB',
	STAR_PARTS = 'STAR_PARTS',
}

export const AVAILABLE_PRODUCTS_WITH_PPV = [ProductKeys.WIS, ProductKeys.XOT_TITLED];

export const SHOWN_LICENSES_ON_APPLICATIONS_TAB = [
	ProductKeys.WIS,
	ProductKeys.ASRA,
	ProductKeys.PARTS_INFORMATION,
	ProductKeys.XENTRY_TIPS,
	ProductKeys.XENTRY_TIPS_MOBILE,
	ProductKeys.XRD,
	ProductKeys.B2B_CONNECT_APP,
];

export const BUTTON_HIDDEN_PRODUCTS = [ProductKeys.XRD, ProductKeys.XENTRY_TIPS_MOBILE];
export const LICENSES_WITH_VEHICLE_TYPES = [
	ProductKeys.WIS,
	ProductKeys.ASRA,
	ProductKeys.XENTRY_TIPS,
	ProductKeys.XRD,
	ProductKeys.XENTRY_TIPS_MOBILE,
	ProductKeys.B2B_CONNECT_APP,
];

export enum FinalVehicleType {
	PC = 'PC',
	VAN = 'VAN',
	SMART = 'SMART',
	PKW = 'PKW',
}

export const VEHICLE_LICENSE_IMAGE_MAPPING = {
	[FinalVehicleType.PC]: IconType.PC_VEHICLE_TYPE,
	[FinalVehicleType.VAN]: IconType.VAN_VEHICLE_TYPE,
	[FinalVehicleType.SMART]: IconType.SMART_VEHICLE_TYPE,
};

export const VEHICLE_LICENSE_SIZE_MAPPING = {
	[FinalVehicleType.PC]: IconSize.LARGE,
	[FinalVehicleType.VAN]: IconSize.LARGE,
	[FinalVehicleType.SMART]: IconSize.MEDIUM,
};

export enum TechnicalDataPageType {
	TECHNICAL_INFORMATION = 'TECHNICAL_INFORMATION',
	DIAGNOSTIC_DATA = 'DIAGNOSTIC_DATA',
	REMOTE_SOLUTIONS = 'REMOTE_SOLUTIONS',
	API = 'API',
}

export enum DiagnosisProductsBoxType {
	EXTENSIONS = 'EXTENSIONS',
	PREPARE_PC = 'PREPARE_PC',
	PURCHASE_SYSTEM = 'PURCHASE_SYSTEM',
	PURCHASE_TIME_CREDITS = 'PURCHASE_TIME_CREDITS',
}

export enum PtiBoxes {
	COPY_DOCUMENT = 'COPY_DOCUMENT',
	ACCESS_PTI = 'ACCESS_PTI',
	WIS = 'WIS',
}

export enum XentryRemoteMobileBoxes {
	DOWNLOAD = 'DOWNLOAD',
	PURCHASE = 'PURCHASE',
	LOGIN = 'LOGIN',
}

export const DURATION_TYPE_TO_TRANSLATION = {
	[SubscriptionTypes.HOUR]: 'for-hour',
	[SubscriptionTypes.DAY]: 'for-day',
	[SubscriptionTypes.WEEK]: 'for-week',
	[SubscriptionTypes.YEAR]: 'for-year',
};

export enum LicenseType {
	RMI = 'rmi',
	DIAGNOSIS = 'diagnosis', // TODO: will be implemented
}

export enum PartProductVehicle {
	CARS = 'CARS',
	VANS = 'VANS',
}

export const GenuineProductFilterTranslationMapping = {
	[PartProductVehicle.CARS]: 'products:genuine-product-filter-cars',
	[PartProductVehicle.VANS]: 'products:genuine-product-filter-vans',
};

export const DEFAULT_GENUINE_PRODUCT_FILTER_TYPE = PartProductVehicle.CARS;

export enum RemanVehicleType {
	CARS = 'Car',
	VANS = 'Van',
	SMART = 'smart',
}

export const MAP_TO_REMAN_VEHICLES = {
	[RemanVehicleType.CARS]: PartProductVehicle.CARS,
	[RemanVehicleType.VANS]: PartProductVehicle.VANS,
	[RemanVehicleType.SMART]: PartProductVehicle.CARS,
};

export enum SearchWidgetThemes {
	LIGHT,
	DARK,
}

export enum SearchWidgetContentType {
	REMAN_DEFAULT,
	ENGINE_LEVEL,
}

export const SearchWidgetStyle = {
	[SearchWidgetThemes.DARK]: {
		classes: 'color-white b-1 bc-brownish-grey',
		inputTheme: InputThemes.DEFAULT,
	},
	[SearchWidgetThemes.LIGHT]: {
		classes: 'color-black b-1 bc-very-light-pink-3 ',
		inputTheme: InputThemes.DEFAULT,
	},
};

export enum RemanRouteType {
	HOME = 'remanufactured-parts',
	VEHICLE = 'vehicle',
	CATEGORIES = 'categories',
	PRODUCTS = 'products',
	ENGINE_SUGGESTIONS = 'engine-suggestions',
	YOUR_BENEFITS = 'your-benefits',
	MANUFACTURING_PROCESS = 'our-manufacturing-process',
}

export enum GenuineRouteType {
	HOME = 'genuine-parts',
	YOUR_BENEFITS = 'your-benefits',
	CUSTOM_PAGE = 'custom',
	VEHICLE = 'vehicle',
	CATEGORIES = 'categories',
	PRODUCTS = 'products',
}

export enum PartType {
	GENUINE_PART = 'genuine-part',
	REMAN_PART = 'reman-part',
}
