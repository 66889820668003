import requestData from '@Network/index';
import {
	ActiveUsersCountResponse,
	AllMarketsResponse,
	AppSettingResponse,
	MarketConfigurationResponse,
} from '@IspTypes/configuration/responseTypes';
import { ApplicationSettingsType, ConfigurationType } from '@Reducers/configuration/models';

export function getAllMarkets() {
	return requestData('GET', 'MARKETS', { shouldRetry: true })
		.then((res: AllMarketsResponse) => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getMarketConfigurations(market: string): Promise<ConfigurationType> {
	return requestData('GET', 'MARKET_CONFIGURATION', { interpolate: { id: market }, shouldRetry: true })
		.then((res: MarketConfigurationResponse) => {
			return res.data;
		})
		.catch(() => {
			return null;
		});
}

export function getApplicationSettings(): Promise<ApplicationSettingsType> {
	return requestData('GET', 'APP_SETTINGS', { shouldRetry: true })
		.then((res: AppSettingResponse) => {
			return res.data;
		})
		.catch(() => {
			return null;
		});
}

export function getActiveUserCount() {
	return requestData('GET', 'ACTIVE_USER_COUNT')
		.then((res: ActiveUsersCountResponse) => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}
