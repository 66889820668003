import { ReactElement } from 'react';
import transform from 'lodash/transform';
import parse from 'html-react-parser';
import { renderToStaticMarkup } from 'react-dom/server';
import client from '@Umbraco/client';
import CONFIG from '@Umbraco/config.json';
import { Image, Video, toImage, toVideo } from '@Umbraco/models';
import { isVideo } from '@Helpers/common/media';
import { VideoSubtitle } from '@isp/video-player';
import { toVideoSubtitles } from '@Umbraco/communication-event';

const emptyHomeData = {
	headerBanner: {
		title: '',
		description: '',
		image: null,
	},
	sections: [],
	videoModule: {
		video: null,
		videoSubtext: '',
		thumbnailImage: null,
		videoSubtitles: [],
	},
};

const toBenefitTab = tab => ({
	tabTitle: tab.tabTitle,
	description: renderToStaticMarkup(parse(tab?.description, { replace: transform }) as ReactElement) || '',
	id: tab._id,
	illustration: toImage(tab.illustration),
});

export const toBenefitModuleData = data => ({
	descriptiveText: data?.descriptiveText,
	headline: data?.headline,
	tabs: data?.tabs?.map(tab => toBenefitTab(tab)),
	_id: data?._id,
});

const toPlatformPreviewTab = tab => ({
	tabTitle: tab.tabTitle,
	description: renderToStaticMarkup(parse(tab?.description, { replace: transform }) as ReactElement) || '',
	id: tab._id,
	media: isVideo(tab?.media?._url) ? toVideo(tab?.media) : toImage(tab?.media),
});

export const toPlatformPreviewModuleData = data => ({
	title: data?.title,
	tabs: data?.tabs?.map(tab => toPlatformPreviewTab(tab)),
	_id: data?._id,
});

const toQuoteItem = item => ({
	chipLabel: item?.chipLabel,
	ispImage: toImage(item?.ispImage),
	subtext: item?.subtext,
	quote: renderToStaticMarkup(parse(item?.quote, { replace: transform }) as ReactElement) || '',
});

export const toQuoteModuleData = data => ({
	quoteList: data?.quoteList?.map(quote => toQuoteItem(quote)),
	_id: data?._id,
});

const toHomePageData = item => ({
	headerBanner: {
		title: item?.title || '',
		description: renderToStaticMarkup(parse(item?.description, { replace: transform }) as ReactElement) || '',
		image: toImage(item?.image) || null,
	},
	videoModule: {
		video: toVideo(item?.video),
		videoSubtext: item?.videoSubtext || '',
		thumbnailImage: toImage(item?.thumbnailImage),
		videoSubtitles: toVideoSubtitles(item?.videoSubtitles),
	},
	sections: item?.sections || [],
});

export interface PlatformPreviewTab {
	tabTitle: string;
	description: string;
	media: Video | Image;
	id: string;
}

export interface PlatformPreviewData {
	title: string;
	tabs: PlatformPreviewTab[];
	_id: string;
}

export interface BenefitTab {
	tabTitle: string;
	description: string;
	illustration: Image;
	id: string;
}

export interface BenefitModuleData {
	descriptiveText: string;
	headline: string;
	tabs: BenefitTab[];
	_id: string;
}

export interface QuoteModuleData {
	chipLabel: string;
	ispImage: Image;
	quote: string;
	subtext: string;
	_id: string;
}

type HomePageSectionType = BenefitModuleData | PlatformPreviewData | QuoteModuleData;

interface HeaderBanner {
	title: string;
	description: string;
	image: Image;
}

interface VideoModuleData {
	video: Video;
	videoSubtext: string;
	thumbnailImage: Image;
	videoSubtitles: VideoSubtitle[];
}

export interface HomePageData {
	headerBanner: HeaderBanner;
	sections: HomePageSectionType[];
	videoModule: VideoModuleData;
}

export function getHome(market: string, lang: string): Promise<HomePageData> {
	const id = CONFIG.HOME;

	return client(`/content/${id}`, market, lang)
		.then(res => {
			try {
				return toHomePageData(res);
			} catch {
				return emptyHomeData;
			}
		})
		.catch(() => {
			return emptyHomeData;
		});
}
